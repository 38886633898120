import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { globalStateContext, dispatchStateContext } from '../App.js';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useConfig, useConfigDispatch } from './ViewConfig/ConfigProvider';
import { addValues, getEnabledCategories, getEnabledRheoMaxCategories, getActiveRiverbanks } from './ViewConfig/ConfigHelpers';
import { useMemo } from 'react';
export const MyIcon = (props)=> {
  const { style, ...otherProps } = props;
  const colorStyle = {
      color: 'white',
  };
  const styles = { ...style, ...colorStyle };
  return <ExpandMoreIcon {...otherProps} style={styles} />
}

export const useStyles = makeStyles((theme) => ({
    //other part of the code//
    paper: {
      background: '#273746',
      color: "white"
    },
    select: {
      "& ul": {
          backgroundColor: "#273746",
      },
      "& li": {
        color: 'white',
    },
    },
    icon: {
      fill:'white',
      color: 'white',
    },
    input: {
        maxHeight: "50px",
        color: 'white',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#273746',
        background: '#273746',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '15px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
          backgroundColor: '#273746',
          color: 'white',
        },
      "&:selected": {
        color: 'white',
      },
    }
  }));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    maxHeight: '50px',
    color: 'white',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#273746',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '15px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#273746',
      color: 'white',
      background: '#273746',
    },
  },
  icon: {
    fill: 'white',
  },
}));

const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
  ];

export default function TargetForm(props) {
    const { singleBoundary } = props;
    const lowerBoundRef = React.useRef('');
    const upperBoundRef = React.useRef('');
    const lowLowBoundRef = React.useRef('');
    const highHighBoundRef = React.useRef('');
    const [state, dispatch] = useGlobalState();
    const [whichTarget, setWhichTarget] = React.useState('Select Category');
    const [lowerBoundValue, setLowerBoundValue] = React.useState('');
    const [upperBoundValue, setUpperBoundValue] = React.useState('');
    const [lowLowBoundValue, setLowLowBoundValue] = React.useState('');
    const [highHighBoundValue, setHighHighBoundValue] = React.useState('');
    const viewConfig = useConfig()
    const configDispatch = useConfigDispatch()
    const classes = useStyles();

    let targetList =useMemo(()=>getActiveRiverbanks(viewConfig),[viewConfig]);
    const rheomaxlist = useMemo(()=>getEnabledRheoMaxCategories(viewConfig),[viewConfig]);
    const categoryList = useMemo(()=>getEnabledCategories(viewConfig),[viewConfig]);
    /* for (let item of state.data) {
        if (item.group === "RheoMax") {
            rheomaxlist.push(...item.categories);
            for (let category of item.categories) {
                if (category.isTargetEnabled === true) {
                    targetList.push(category);
                }
            }
        } else {
            categoryList.push(...item.categories);
            for (let category of item.categories) {
                if (category.isTargetEnabled === true) {
                    targetList.push(category);
                }
            }
        }
    }
    console.log(categoryList) */
    const targetRemover = (target) => {
        const targetValue = target;
        let temp = state;
        for (let item of temp.data) {
            for (let category of item.categories) {
                if (category.datakey === targetValue) {
                    category.isTargetEnabled = false;
                    category.targets.min = NaN;
                    category.targets.max = NaN;
                    category.targets.lowLow = NaN;
                    category.targets.highHigh = NaN;
                    category.targets.isDoubleBoundary = false;
                }
            }
       }
       dispatch(temp);
    }
    const handleTargetRemove=(target)=>{
      const newConfig = {...viewConfig}
      newConfig.charts.forEach((chart,chartName)=>{
        chart.categories.forEach((category,categoryname)=>{
          if(categoryname == target){
            const riverbank = category.riverbank
            riverbank.innerMax = null
            riverbank.outerMax = null
            riverbank.innerMin = null
            riverbank.outerMin = null
            riverbank.riverbankEnabled = false
            riverbank.doubleBankEnabled = false
            category.riverbank = riverbank
          }
        })
      })
      console.log('dispatching:',newConfig)
      configDispatch({
          type:'updateConfig',
          chart:null,
          category:null,
          config:newConfig
        })
    }
    const RheoSelect = categoryList.map(category => {
        return (       
            <MenuItem key={category.name} value={category.name}>{category.name}</MenuItem>
        )
    });      
    const RheoMaxSelect = rheomaxlist.map(category => {
        return (
            <MenuItem key={category.name} value={category.name} disabled>{category.name}</MenuItem>
        )
    });
    const RheoRemover = targetList.map(category => {
        return (
            <Box key={category.name} sx={{border: 'solid 1px white', borderRadius: '10px', display: 'flex', flexDirection: 'row', mx:1}}>
            <Typography component="p" variant="text1" sx={{color: 'white', mt:1, pl: 1}}>{category.name}: (LL: {category.riverbank.outerMin}, L: {category.riverbank.innerMin}, H: {category.riverbank.innerMax}, HH: {category.riverbank.outerMax})</Typography>
            <IconButton sx={{ px: 0.5 }} onClick={() => handleTargetRemove(category.name)}>
                <CloseIcon fontSize='medium' sx={{color: red[600]}}/>
            </IconButton>
            </Box>
        )
    })
    const handleChange = (event) => {
      setWhichTarget(event.target.value);
      setLowerBoundValue('');
      setUpperBoundValue('');    
    };
    const handleLowerBoundChange = (event) => {
      setLowerBoundValue(event.target.value);
    };
    const handleUpperBoundChange = (event) => {
      setUpperBoundValue(event.target.value);
    };
    const handleHighHighBoundChange = (event) => {
      setHighHighBoundValue(event.target.value);
    }; 
    const handleLowLowBoundChange = (event) => {
      setLowLowBoundValue(event.target.value);
    };
    function getValues(){
      const lowerBound = parseFloat(lowerBoundRef.current.value);
      const upperBound = parseFloat(upperBoundRef.current.value);
      const lowLow = parseFloat(lowLowBoundRef.current.value);
      const highHigh = parseFloat(highHighBoundRef.current.value);
      const values = {
        innerMin:lowerBound,
        innerMax:upperBound,
        outerMin:lowLow,
        outerMax:highHigh}
      if(lowLow == NaN){
        values.outerMin=null
      }
      if(highHigh == NaN){
        values.outerMax=null
      }
    return values
    }
    const sendValue = () => {
        const values = getValues()
        let tempConfig = {...viewConfig}
        tempConfig = addValues(tempConfig, values, whichTarget)
        console.log(values)
        setLowerBoundValue('');
        setUpperBoundValue('');
        setLowLowBoundValue('');
        setHighHighBoundValue('');
        console.log('dispatching:',tempConfig)
        configDispatch({
          type:'updateConfig',
          chart:null,
          category:null,
          config:tempConfig
        })
    }
    if (singleBoundary) {

    return (
        <div style={{backgroundColor: '#273746', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
        <FormControl sx={{ m: 1, minWidth: 140, width: {xs: '100%', md: 170}}}>
        <FormHelperText sx={{color: 'white'}}>Select parameter</FormHelperText>
        <Select
          sx={{backgroundColor: '#273746'}}
          MenuProps={{
            classes: {
              paper: classes.select
            }
          }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={whichTarget}
          onChange={handleChange}
          input={<BootstrapInput />}
          inputProps={{classes: {input: classes.input, icon: classes.icon}}}
          IconComponent={MyIcon}
          
          
        >
          {RheoSelect}
          {RheoMaxSelect}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 100, width:200 }} variant="standard">
        <FormHelperText sx={{color: 'white'}}>Set lower bound</FormHelperText>
        <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='Lower bound...' id="demo-customized-textbox" 
        value={lowerBoundValue} onChange={handleLowerBoundChange} inputRef={lowerBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}
        />       
        
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 100, width: 200 }} variant="standard">
        <FormHelperText sx={{color: 'white', alignContent: 'center'}}>Set upper bound</FormHelperText>
        <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='Upper bound...' id="demo-customized-textbox" 
        value={upperBoundValue} onChange={handleUpperBoundChange} inputRef={upperBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}       
        />           
      </FormControl>
      <FormControl sx={{ m: 1, mt:2, minWidth: 100, p:'15px 26px 10px 12px' }} variant="standard">
        <Button variant="contained" sx={{height: '50px'}} onClick={sendValue}>Add riverbank</Button>        
      </FormControl>
    {targetList.length > 0 ?
    <Box
        component="form"
        sx={{ m: '20px 0px 0px 0px', display: 'flex', alignItems: 'center', backgroundColor:'#273746', maxHeigth:'30px', px: 2, flexWrap:'wrap'}}
    >
     {RheoRemover}
    </Box>
    : null}
    </div>
    );
  } else {
    return (
      <div style={{backgroundColor: '#273746', display:'flex', flexDirection:'column', flexWrap:'wrap'}}>
      <Stack direction="row">
      <FormControl sx={{ m: 1, minWidth: 140, width: {xs: '100%', md: 170}}}>
      <FormHelperText sx={{color: 'white'}}>Select parameter</FormHelperText>
      <Select
        sx={{backgroundColor: '#273746'}}
        MenuProps={{
          classes: {
            paper: classes.select
          }
        }}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={whichTarget}
        onChange={handleChange}
        input={<BootstrapInput />}
        inputProps={{classes: {input: classes.input, icon: classes.icon}}}
        IconComponent={MyIcon}
        
        
      >
        {RheoSelect}
        {RheoMaxSelect}
      </Select>
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 100, width:200 }} variant="standard">
      <FormHelperText sx={{color: 'white'}}>Set low-low boundary</FormHelperText>
      <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='low-low boundary...' id="demo-customized-textbox" 
      value={lowLowBoundValue} onChange={handleLowLowBoundChange} inputRef={lowLowBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}
      />       
      
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 100, width:200 }} variant="standard">
      <FormHelperText sx={{color: 'white'}}>Set low boundary</FormHelperText>
      <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='low boundary...' id="demo-customized-textbox" 
      value={lowerBoundValue} onChange={handleLowerBoundChange} inputRef={lowerBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}
      />       
      
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 100, width: 200 }} variant="standard">
      <FormHelperText sx={{color: 'white', alignContent: 'center'}}>Set high boundary</FormHelperText>
      <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='high boundary...' id="demo-customized-textbox" 
      value={upperBoundValue} onChange={handleUpperBoundChange} inputRef={upperBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}       
      />           
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 100, width: 200 }} variant="standard">
      <FormHelperText sx={{color: 'white', alignContent: 'center'}}>Set high-high boundary</FormHelperText>
      <InputBase sx={{input : {color: 'white'}}} className={classes.input} placeholder='high-high boundary...' id="demo-customized-textbox" 
      value={highHighBoundValue} onChange={handleHighHighBoundChange} inputRef={highHighBoundRef} onKeyDown={(e) => {if (e.key === 'Enter') {sendValue()}}}       
      />           
    </FormControl>
    <FormControl sx={{ m: 1, mt:2, minWidth: 100, p:'15px 26px 10px 12px' }} variant="standard">
      <Button variant="contained" sx={{height: '50px'}} onClick={sendValue}>Add riverbank</Button>        
    </FormControl>
    </Stack>
  {targetList.length > 0 ?
  <Box
      component="form"
      sx={{ m: '20px 0px 0px 0px', display: 'flex', backgroundColor:'#273746', maxHeigth:'30px', p: 2, flexWrap:'wrap'}}
  >
   {RheoRemover}
  </Box>
  : null}
  </div>
  );
  }
}