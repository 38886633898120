
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, CartesianAxis, ResponsiveContainer, Label } from 'recharts';
import React, { useEffect, useState } from "react";
import {TickFormat} from '../Helper/TickFormat';
import {TooltipLabelFormat} from '../Helper/TooltipLabelFormat';
import {TooltipFormat} from '../Helper/TooltipFormat';
import {XaxisTickFormat} from '../Helper/XaxisTickFormat'
import {AxisTicks} from '../Helper/AxisTicks';
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AxisFromStats from '../Helper/AxisFromStats';
import StatsMUI from '../components/StatsMUI';
import { HistoricTickFormat } from '../Helper/HistoricTickFormat';
import { Backdrop } from '@mui/material';
import { globalStateContext, dispatchStateContext } from '../App.js';
import { useConfig, ConfigContext } from './ViewConfig/ConfigProvider';
import { useRender, useRenderDispatch } from './ViewConfig/RenderProvider';

const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext)
];

const Charts = (props) => {
    
    const { error, isLoaded,chart, items, colours, groupName, groupIndex, yAxis, stats, myKey, isMoreThan720, tempStats, hideYaxis, yAxisOrientation, isHistoric, delta } = props;
    
    let height = parseInt(960*0.8);
    let width = parseInt(450*0.8);
    let chartWidth = parseInt(450*0.8)
    let hideYAxis = hideYaxis
    let yAxisOrientationVar = yAxisOrientation
    const [state, dispatch] = useGlobalState();
    const viewConfig = useConfig()
    const render = useRender()
    console.log(items)
    const [configChanged, setConfigChanged] = useState(false)
    if(hideYaxis){
      chartWidth = 264
    }
    if(!isMoreThan720){
      height = 620
      width = 220
      chartWidth = 220
      hideYAxis = false
      yAxisOrientationVar = 'left'
    }
    let centerStats = false

    useEffect(()=>{
      console.log("flipped")
      setConfigChanged(!configChanged)
    },[viewConfig])

    useEffect(()=>{
      console.log("flipped")
      setConfigChanged(!configChanged)
    },[render])
    
    if(yAxisOrientationVar=='left' && !hideYaxis){
      centerStats = 'true'
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } 
    else {
      let XAxes = AxisFromStats(stats, state)
      //console.log(viewConfig.charts)
      //console.log(`Creating chart for ${groupName} with x-axis: ${XAxes} and y-axis: ${yAxis}, ${hideYAxis}, ${yAxisOrientationVar}`)
    return(
    
    <Box key={myKey} name='GroupBox' sx={{display:'flex', flexDirection:'column', justifyItems:'center', alignContent:'center', width:{width}, zIndex: 0}}>
      
      <Typography variant="h6" component='h1' sx={{color: 'white', mx:'auto'}}>{groupName}</Typography>
      <Box name='StatsBox' style={{display:'flex',justifyContent: centerStats ? 'center':'normal', width:{width}, minWidt:292, }}>
        <StatsMUI stats = { stats } categories = { Array.from(viewConfig.charts.get(chart).categories.values()) } updatedStats={tempStats} liveitems={items} colours={colours} chartIndex={groupIndex}/> 
      </Box>
    
      
     <LineChart height={height} minWidth={parseInt(300*0.8)} width={chartWidth} minHeight={200} key={myKey}className='charts'  data={items} margin={{ top: 10, right: 30, bottom: 5, left: 10 }} layout='vertical'>
      <CartesianAxis />
      <CartesianGrid stroke="#6f97b5" fill='#34495e'  />
      
      {
      Array.from(viewConfig.charts.get(chart).categories.values()).map((category,index)=>{
       
        if (category.enabled === true){

            let xAxis = XAxes[category.datakey]
            let ticks= AxisTicks(xAxis, 5)
            return (<React.Fragment key={category.name}>
              <Line  xAxisId={index}  key = {"Line"+index} name={category.datakey === "t_inlet_m" ? "temp C°" : category.name} dataKey={category.datakey} type="monotone" stroke={colours[index+groupIndex]} strokeWidth={2} isAnimationActive={false} dot={false} allowDataOverflow={true} connectNulls={true} />
              {category.riverbank.riverbankEnabled &&
              <>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.innerMin}/>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine2"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.innerMax}/>
              </>
              
              }
              {category.riverbank.doubleBankEnabled &&
              <>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.innerMin}/>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine2"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.innerMax}/>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine3"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.outerMin}/>
                <ReferenceLine xAxisId={index} key = {"ReferenceLine4"+index} name={category.name} stroke={colours[index+groupIndex]} strokeDasharray="8 8" strokeWidth={2} x={category.riverbank.outerMax}/>
              </>
              }
              <XAxis xAxisId={index}  key={"axis"+index}type="number" domain={xAxis}  orientation="bottom" dataKey={category.datakey} 
              allowDataOverflow={true} 
              ticks={ticks}
               tickLine={{ stroke: colours[index+groupIndex] }} tick={{ fill: colours[index+groupIndex], wordWrap: 'break-word' }} axisLine={{ stroke: colours[index+groupIndex]}} 
               tickFormatter={XaxisTickFormat} interval={0} 
               ></XAxis>
              
            </React.Fragment>);
        }
        }
     )}
     <Backdrop sx={{position: 'relative'}} open={isLoaded}> <CircularProgress /> </Backdrop>
     {isHistoric
      ? <YAxis dataKey="timestamp" type="number" width={50} tickCount={delta > 24 ? 7 : 5} domain={yAxis} ticks={delta > 24 ? AxisTicks(yAxis,7) : AxisTicks(yAxis,5)} tickFormatter={delta > 24 ? HistoricTickFormat : TickFormat} stroke="white" axisLine={false} interval={0} hide={hideYAxis} orientation={yAxisOrientationVar} allowDataOverflow={true}/>
      : <YAxis dataKey="timestamp" type="number" width={50} tickCount={5} domain={yAxis} ticks={AxisTicks(yAxis,5)} tickFormatter={TickFormat} stroke="white" axisLine={false} interval={0} hide={hideYAxis} orientation={yAxisOrientationVar} allowDataOverflow={true}/>
      }
      <Tooltip contentStyle={{backgroundColor:'#273746', color:'white'}} labelFormatter={TooltipLabelFormat} formatter={TooltipFormat}/>

     </LineChart>
     </Box>
     
      
    )
    }
  }
  
  export default Charts;
  