import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';
import rheoview from '../assets/rheoview.png';
import rheomax from '../assets/rheomax.png';
import rheoport from '../assets/rheoport.png';
import rheoadvise from '../assets/rheoadvise.png';
import rheobrain from '../assets/rheobrain.png';


const useStyles = makeStyles((theme) => ({
    divider: {
      background: 'white',
    }
}));
const Features = () => {
    const classes = useStyles();

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '90vw', height: '50vh', justifyContent: 'center', alignContent: 'center', mt: {xs: 30, md: 1}}}>
            <Card sx={{width: '15%', minWidth: '300px', m:2, display: 'flex', backgroundColor: '#0A1929'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Stack direction="row" spacing={5}>
                            <div style={{height: '40px', width: '40px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}    >
                                <img src={rheoview} style={{height: '40px', width: '40px', borderRadius: '50%', marginTop: '5px'}} />
                            </div>
                            <Typography variant="h4" component="h4" sx={{color: 'white'}}>
                                RheoView
                            </Typography>
                        </Stack>
                        <Divider horizontal classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5, mt: 1}} />
                        <Typography variant="body2" component="p" sx={{color: 'white', mt: 2}}>
                            RheoView real-time monitoring system helps engineers to evaluate and react to current events in the fluid process. 
                            Your organization can log and track real-time data to identify trends and better recognize abnormalities that fall outside set parameters of system and application behavior. 
                        </Typography>
                    </CardContent>
                </Box>
            </Card>
            
            <Card sx={{width: '15%', minWidth: '300px', m:2, display: 'flex', backgroundColor: '#0A1929'}}>
                <CardContent>
                    <Stack direction="row" spacing={5}>
                        <div style={{height: '40px', width: '40px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                            <img src={rheomax} style={{height: '40px', width: '40px', borderRadius: '50%'}} />
                        </div>
                        <Typography variant="h4" component="h4" sx={{color: 'white'}}>
                            RheoMax
                        </Typography>
                    </Stack>
                    <Divider horizontal classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5, mt: 1}} />
                    <Typography variant="body2" component="p" sx={{color: 'white', mt: 2}}>
                        The RheoMax technologies offers a wide range off addon instrumentation to increase the amount of autonomous fluid parameters for real time trending and analysis.   
                    </Typography>
                </CardContent>
            </Card>
            
            <Card sx={{width: '15%', minWidth: '300px', m:2, display: 'flex', backgroundColor: '#0A1929'}}>
                <CardContent>
                    <Stack direction="row" spacing={5}>
                        <div style={{height: '40px', width: '40px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                            <img src={rheoport} style={{height: '40px', width: '35px', borderRadius: '50%', marginTop: '5px', marginLeft: '5px'}} />
                        </div>
                        <Typography variant="h4" component="h4" sx={{color: 'white'}}>
                            RheoPort
                        </Typography>
                    </Stack>
                    <Divider horizontal classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5, mt: 1}} />
                    <Typography variant="body2" component="p" sx={{color: 'white', mt: 2}}>
                        RheoReport is fluid reports are created and automatically delivered to specific email addresses on a regular basis.
                        The reports contains fluid data with a 24 hrs. historical perspective synced to the assets NPT clock. 
                    </Typography>
                </CardContent>
            </Card>
            
            <Card sx={{width: '15%', minWidth: '300px', m:2, display: 'flex', backgroundColor: '#0A1929'}}>
                <CardContent>
                    <Stack direction="row" spacing={5}>
                        <div style={{height: '40px', width: '40px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                            <img src={rheoadvise} style={{height: '40px', width: '40px', borderRadius: '50%'}} />
                        </div>
                        <Typography variant="h4" component="h4" sx={{color: 'white'}}>
                            RheoAdvise
                        </Typography>
                    </Stack>
                    <Divider horizontal classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5, mt: 1}} />
                    <Typography variant="body2" component="p" sx={{color: 'white', mt: 2}}>
                        The RheoAdvise technology will gather the complete data stream from RheoSense and RheoMax. 
                        The data gathered is analyzed by a powerful AI which again generates advices, in real time, to the fluid specialist
                    </Typography>
                </CardContent>
            </Card>
            
            <Card sx={{width: '15%', minWidth: '300px', m:2, display: 'flex', backgroundColor: '#0A1929'}}>
                <CardContent>
                    <Stack direction="row" spacing={5}>
                        <div style={{height: '40px', width: '40px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px'   }}>
                            <img src={rheobrain} style={{height: '40px', width: '40px', borderRadius: '50%'}} />
                        </div>
                        <Typography variant="h4" component="h4" sx={{color: 'white'}}>
                            RheoBrain
                        </Typography>
                    </Stack>
                    <Divider horizontal classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5, mt: 1}} />
                    <Typography variant="body2" component="p" sx={{color: 'white', mt: 2}}>
                        The RheoBrain technology will gather the complete data stream form RheoSense, RheoMax and RheoAdvise. 
                        The complete data set is utilized to optimize fluid management ensuring optimized fluid parameters at any given time. 
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
};
export default Features;