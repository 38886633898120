function isLessThanTimeAgo(datapoint, time){
    let epochHourAgo = (Date.now() -(time*1000));
    if(datapoint.timestamp<=epochHourAgo){
        return false
    }
    return true
}
function findCuttof(skidArray, time){
    for(let i = 0; i<skidArray.length-1;i++){
        if(isLessThanTimeAgo(skidArray[i], time)){
            
            return i
        }
    }
}
function pruneArray(skidArray, time){
    skidArray.splice(0,findCuttof(skidArray, time))
    return skidArray
}

export function pruneCache(cache,cacheTimeSize){
    
    pruneArray(cache, cacheTimeSize)
    return cache
}

export default pruneCache;