import React, {useContext} from 'react'
import {  Typography, Box } from '@mui/material';
import { useMediaPredicate } from "react-media-hook";
import { DataPoint } from '../../Pages/Historic';
import Charts from '../Charts';
import StatsObject from '../../Helper/StatsObject';
import {ConfigContext, useConfig, useConfigDispatch} from '../ViewConfig/ConfigProvider'
import { StatsCollection, isChartActive } from '../../Helper/Chart/chartIsActive';
import { isAxisHidden, determineYaxisOrientation } from '../../Helper/Chart/yAxisHelpers';
import DefineYAxis from '../../Helper/DefineYAxis';
import { epochSecondsToHours } from '../../Helper/timeHelpers';
import { getTimeDelta } from '../../Helper/Chart/getTimeDelta';
import { useRender, useRenderDispatch } from '../ViewConfig/RenderProvider';

interface ChartViewProps{
    data: DataPoint[]
    loading: boolean
    start:number
    end:number
}
function ChartView(props:ChartViewProps){
    const viewConfig = useContext(ConfigContext)
    const {data, loading, start, end } = props
    const stats = new StatsObject(data)
    const moreThan720 = useMediaPredicate("(min-width: 720px)");
    const DeltaHours = epochSecondsToHours(end-start)
    const yAxis = [start, end]
    const render = useRender()
    console.log( new Date(yAxis[0]).toLocaleString(),new Date(yAxis[0]).toLocaleString())
    let numActive = 0
    Array.from(viewConfig.charts.values()).map((chart)=>{
      if(isChartActive(chart,stats.toCollection())){
        numActive=numActive+1
      }
    })
    return(
      <>
      {
        Array.from(viewConfig.charts.values()).
        map((chart,index)=>{
          console.log(chart,viewConfig.charts)
          if(isChartActive(chart,stats.toCollection())){
            console.log('is active')

            let hideYAxis = isAxisHidden(index,numActive)
            let yAxisOrientation = determineYaxisOrientation(index,numActive)
            return (
                <Charts 
                  key={chart.name} 
                  items = {data} 
                  isLoaded = {loading} 
                  error = {null} 
                  categories = {Array.from(chart.categories.values())} 
                  chart = {chart.name}
                  colours ={['#83EEFF','yellow',' #FF00FF ', '#ff9800','#1cec12', ' #03a9f4']} 
                  groupName={chart.name} 
                  groupIndex={index} 
                  yAxis={yAxis} 
                  stats = {stats} 
                  isMoreThan720={moreThan720} 
                  tempStats ={stats} 
                  hideYaxis = {hideYAxis} 
                  yAxisOrientation = {yAxisOrientation} 
                  isHistoric = {true} 
                  delta={DeltaHours} 
                  />)
          }   
        })
      }
      
      </>
        
    )
}
export default ChartView