import { EventMessageUtils } from "@azure/msal-browser";
import { DisplayConfig, Chart } from "../ViewConfig/ConfigProvider";

interface ReportFetchOptions{
    start: number;
    end: number;
    skid: string;
    resample_rate: number;
    emailStatus: boolean;
    emails: Set<string>;
    length: number;
}

function validateReportFetch(o:ReportFetchOptions){
    var error:string
    if (o.start>o.end){
        error = `Start ${new Date(o.start).toLocaleString()} is greater than End ${new Date(o.end).toLocaleString()}`
        return error
    }
    if(o.skid==''){
        error= 'No Skid selected'
        return error
    }
    if (o.length>24*7){
        'Can not generate report longer than 7 days'
        return error
    }
    if (o.emailStatus){
        if (o.emails.entries.length===0){
            error
        }
    }
    error ='ok'
    return error
}
export default validateReportFetch