import { Action } from "./SkidsProvider"
export function addSkid(skids:Set<string>,action:Action):Set<string>{
    skids.add(action.skid)
    return skids
}
export function addSkids(skids:Set<string>,action:Action):Set<string>{
    action.skids.forEach((skid)=>{
        skids.add(skid)
    })
    return skids
}
export function removeSkid(skids:Set<string>,action:Action):Set<string>{
    skids.delete(action.skid)
    return skids
}
export function removeSkids(skids:Set<string>,action:Action):Set<string>{
    action.skids.forEach((skid)=>{
        skids.delete(skid)
    })
    return skids
}
export function clearSkids(skids:Set<string>):Set<string>{
    skids.clear()
    return skids
}