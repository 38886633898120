import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import ims from '../assets/ims.png';
import BarChartIcon from '@mui/icons-material/BarChart';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DynamicForm from './DynamicForm.js';
import { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import OilBarrelSharpIcon from '@mui/icons-material/OilBarrelSharp';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import FolderIcon from '@mui/icons-material/Folder';
import { BsFillArrowUpLeftSquareFill, BsFillDropletFill } from 'react-icons/bs';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import { GiBrain } from 'react-icons/gi';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { AadSignin } from './AadSignin';
import { AadSignout } from './AadSignout';
import { useIsAuthenticated } from "@azure/msal-react";
import SettingsIcon from '@mui/icons-material/Settings';
import { globalStateContext, dispatchStateContext } from '../App.js';
import NotificationsBell from './NotificationBell';
import DescriptionIcon from '@mui/icons-material/Description';
import {SwitchForm} from './Sidebar/SwitchForm'
import {categoryLabelMaker, boundariesLabelMaker} from './Sidebar/SidebarHelpers'
import {useConfig, useConfigDispatch} from './ViewConfig/ConfigProvider'
import { FormGroup, FormControl } from '@mui/material'
import { useRenderDispatch, useRender } from './ViewConfig/RenderProvider';
import { setBoundary } from './ViewConfig/ConfigHelpers';


const drawerWidth = 240;

const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext)
];

const useStyles = makeStyles((theme) => ({
  divider: {
    background: 'white',
  },
  root: {
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
}))

const openedMixin = (theme) => ({
  background: '#0A1929',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]:{
    width:'70%'
  }
  
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#0A1929',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('md')]:{
    display:'none'
  }
  
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  background: '#0A1929',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isloggedin }) => ({
  background: '#0A1929',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    background: '#0A1929',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]:{
      display:'none'
    }
  }),
  ...(!isloggedin && {
    color: 'transparent'
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    background: '#0A1929',
    backgroundcolor: '#0A1929',
    color: '#0A1929',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      background: '#0A1929',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      background: '#0A1929',
      
    }),
  }),
);


const MobileDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    background: '#0A1929',
    backgroundcolor: '#0A1929',
    color: '#0A1929',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      background: '#0A1929',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      background: '#0A1929',
    }),
  }),
);

const linkStyle = {
  variant: "h1",
  textDecoration: "none",
  color: 'white'
};
const secondLinkStyle = {
  variant: "h6",
  textDecoration: "none",
  color: 'inherit'
};


export default function Sidebar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [liveOpen, setLiveOpen] = React.useState(false);
  const [historicOpen, setHistoricOpen] = React.useState(false);
  const [ mobileDrawerOpen, setMobileDrawerOpen] = useState(true)
  const location = useLocation().pathname;
  const [rheoView, setRheoView] = useState(false);
  const [rheoPort, setRheoPort] = useState(false);
  const [rheoMax, setRheoMax] = useState(false);
  const [rheoAdvice, setRheoAdvice] = useState(false);
  const [rheoBrain, setRheoBrain] = useState(false);
  const [rheoList, setRheoList] = useState(false);
  const [rheoGenerate, setRheoGenerate] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [state, dispatch] = useGlobalState();
  const isAuthenticated = useIsAuthenticated();
  const viewConfig = useConfig();
  const viewConfigDispatch = useConfigDispatch();
  const renderDispatch = useRenderDispatch()
  const [toggled,setToggled] = useState(BsFillArrowUpLeftSquareFill)
  const renderer = useRender()
const { myKey, isLoggedIn, sidebarToggle, notifiCount } = props
  const handleDrawerOpen = () => {
    setOpen(true);
    sidebarToggle(true)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    sidebarToggle(false);
  };
  function categoryToggleHandler(event){
    const action = {
      type:'toggleCategory',
          chart: null,
          category: event.target.value,
          config: null,
    }
    console.log('dispatching:',action)
    const temp = toggled
    viewConfigDispatch(action)
    renderDispatch({type:'flipSwitch'})
    setToggled(!temp)
  } 
  function boundariesToggleHandler(event){
    console.log('boundaries toggled',event.target.value )
    const newConfig = setBoundary(viewConfig,event.target.value)
    console.log('dispatching:',newConfig)
    viewConfigDispatch({
      type:'toggleBoundaries',
      chart: null,
      category: null,
      config: newConfig,
    })
    const temp = toggled
    setToggled(!temp)
    //renderDispatch({type:'flipSwitch'})
  }
  if (isLoggedIn) {
  return (
    <Box 
    key={myKey}
    className={classes.root}
    sx={{ 
      display: 'flex',
      backgroundcolor:"grey",
      borderCollapse: 'collapse',
      }} >
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              color: 'white',
              ...(open && { display: 'none' }),
            }}
          >
          <MenuIcon />
          </IconButton>
          
          
          <Typography component="h1" variant="h6" noWrap sx={{ flexGrow: 2 }}>
          </Typography>
          
          <NotificationsBell notifiCount={notifiCount}/>
          {isAuthenticated
          ? <AadSignout />
          : <AadSignin />
          }
          

          
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{backgroundColor:"#0A1929"}}  >
        <DrawerHeader >
          <Link to='/'>
            <img src={ims} alt="ims-img" style={{width: '11em', height: '2.5em'}}/>
          </Link>
          <IconButton onClick={handleDrawerClose} >
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color: 'white'}} /> : <ChevronLeftIcon sx={{color: 'white'}}/>}
          </IconButton>
        </DrawerHeader>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}} />
        <List>
          <ListItem key={'RheoView'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setRheoView(!rheoView)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <DesktopMacIcon sx={{color: rheoView ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                  <ListItemText primary={'RheoView'} sx={{ opacity: open ? 1 : 0, color: rheoView ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  {open &&
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0.5,
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rheoView ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                    
                  </ListItemIcon>
                  }
                  </ListItemButton> 
          </ListItem>
          {rheoView && <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>}
          {rheoView &&
          <Link key={'Live'} to={'/Live'} style={secondLinkStyle}> 
            <ListItem key={'Live'} disablePadding sx={{
                display: 'block',  
                }} 
                onClick={() => setLiveOpen(!liveOpen)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <BarChartIcon sx={{color: location==='/Live' ? '#83EEFF' : 'white', fontSize: 20}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Live'} sx={{ opacity: open ? 1 : 0, color: location==='/Live' ? '#83EEFF' : 'white'}} />
                    {open &&
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 'auto',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {location==='/Live' ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                      
                    </ListItemIcon>
                    }
                  </ListItemButton>
            </ListItem>       
          </Link>
          }
          {open && location==='/Live' && rheoView ?
            <>
            <List>
            <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
              <FormGroup>
                {categoryLabelMaker(viewConfig.charts,categoryToggleHandler)}
              </FormGroup>
            </FormControl>
            </List>
              <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 2}}/>
              </>
            : null}
          {rheoView &&
          <Link key={'Historic'} to={'/Historic'} style={secondLinkStyle}> 
            <ListItem key={'Historic'} disablePadding sx={{
                display: 'block',  
                }} 
                onClick={() => setHistoricOpen(!historicOpen)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <HourglassBottom sx={{color: location==='/Historic' ? '#83EEFF' : 'white', fontSize: 20}}/>
                    </ListItemIcon>
                    <ListItemText primary={'Historic'} sx={{ opacity: open ? 1 : 0, color: location==='/Historic' ? '#83EEFF' : 'white'}} />
                    {open &&
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 0,
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {location==='/Historic' ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                      
                    </ListItemIcon>
                    }
                  </ListItemButton>
            </ListItem>       
          </Link>
        }
        </List>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        {open && location==='/Historic' && rheoView ?
        <>
        <List>
          <ListItem>
            <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
              <FormGroup>
                {categoryLabelMaker(viewConfig.charts,categoryToggleHandler)}
              </FormGroup>
            </FormControl>
          </ListItem>
        </List>
          <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 2}}/>
          </>
        : null}
        <List>
        <ListItem key={'RheoMax'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setRheoMax(!rheoMax)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <BsFillDropletFill style={{color: rheoMax ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                  <ListItemText primary={'RheoMax'} sx={{ opacity: open ? 1 : 0, color: rheoMax ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  {open &&
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0.5,
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rheoMax ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                    
                  </ListItemIcon>
                  }
                  </ListItemButton> 
          </ListItem>
        </List>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        {open && rheoMax  ?
        <>
        
        <List>
          <ListItem>
            {categoryLabelMaker(viewConfig.rheoMax, categoryToggleHandler)}
          </ListItem>
        </List>
          <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 2}}/>
          </>
        : null}
        <List>
        <ListItem key={'RheoPort'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setRheoPort(!rheoPort)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <FolderIcon sx={{color: rheoPort ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                  <ListItemText primary={'RheoPort'} sx={{ opacity: open ? 1 : 0, color: rheoPort ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  {open &&
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0.5,
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rheoPort ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                    
                  </ListItemIcon>
                  }
                  </ListItemButton> 
          </ListItem>
        </List>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        {open && rheoPort  ?
        <>
        <List>
          <Link to='/RheoPort' style={secondLinkStyle}>
          <ListItem onClick={() => setRheoGenerate(!rheoGenerate)}>
            <ListItemButton>
              <ListItemText primary={'Generate Report'} sx={{ opacity: open ? 1 : 0, color: rheoGenerate ? '#83EEFF' : 'white', fontWeight: 'bold', fontSize: 10}}/>
            </ListItemButton>
          </ListItem>
          </Link>
        </List>
          <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
          </>
        : null}
        <List>
        <ListItem key={'RheoAdvice'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setRheoAdvice(!rheoAdvice)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <SchoolSharpIcon sx={{color: rheoAdvice ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                  <ListItemText primary={'RheoAdvise'} sx={{ opacity: open ? 1 : 0, color: rheoAdvice ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  {open &&
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0.5,
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rheoAdvice ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                    
                  </ListItemIcon>
                  }
                  </ListItemButton> 
          </ListItem>
        </List>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        {rheoAdvice &&
        <>
          <Link key={'documentation'} to={'/documentation'} style={secondLinkStyle}> 
            <ListItem key={'documentation'} disablePadding sx={{
                display: 'block',  
                }} 
                onClick={() => setRheoAdvice(!rheoAdvice)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <DescriptionIcon sx={{color: location==='/documentation' ? '#83EEFF' : 'white', fontSize: 20}}/>
                    </ListItemIcon>
                    <ListItemText primary={'documentation'} sx={{ opacity: open ? 1 : 0, color: location==='/documentation' ? '#83EEFF' : 'white'}} />
                    {open &&
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 'auto',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {location==='/documentation' ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                      
                    </ListItemIcon>
                    }
                  </ListItemButton>
            </ListItem>       
          </Link>
          </>
          }
        {open && rheoAdvice ?
        <>
          <List>           
            <ListItem>
            <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
              <FormGroup>
              {boundariesLabelMaker(boundariesToggleHandler,viewConfig.boundaries)}
              </FormGroup>
            </FormControl>
            </ListItem>
          </List>
          <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        </>
        :null }
        <List>
        <ListItem key={'RheoBrain'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setRheoBrain(!rheoBrain)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <GiBrain style={{color: rheoBrain ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                  <ListItemText primary={'RheoBrain'} sx={{ opacity: open ? 1 : 0, color: rheoBrain ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  {open &&
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0.5,
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rheoBrain ? <ArrowDropDownSharpIcon sx={{color: '#83EEFF'}}/> : <ArrowRightSharpIcon sx={{color: 'white'}}/>}
                    
                  </ListItemIcon>
                  }
                  </ListItemButton> 
          </ListItem>
        </List>
        <Divider classes={{root: classes.divider}} sx={{borderBottomWidth: 1.5}}/>
        <List>
          <Link to='/Settings' style={secondLinkStyle}>
          <ListItem key={'RheoPort'} disablePadding sx={{
                display: 'block',  
                }}
                onClick={() => setOpenSettings(!openSettings)}
                >
                 <ListItemButton>
                 <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    <SettingsIcon sx={{color: location === '/Settings' ? '#83EEFF' : 'white', fontSize: 20}}/>
                    
                  </ListItemIcon>
                    <ListItemText primary={'Configuration'} sx={{ opacity: open ? 1 : 0, color: location === '/Settings' ? '#83EEFF' : 'white', fontWeight: 'bold'}}/>
                  </ListItemButton>
                </ListItem>
                </Link>
              </List>
      </Drawer>
      
      
        
     
      
    </Box>
  );
} else {
  return (
    <Box key={myKey} 
    sx={{ 
      display: 'flex',
      backgroundcolor:"transparent"
      }} >
      <CssBaseline />
      <MuiAppBar position="fixed" color="transparent" elevation={0}>
        <Toolbar color="transparent">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
          <MenuIcon />
          </IconButton>
          
          
          <Typography component="h1" variant="h6" noWrap sx={{ flexGrow: 2 }}>
          </Typography>
          {isAuthenticated
          ? <AadSignout />
          : <AadSignin />
          }

          
        </Toolbar>
      </MuiAppBar>
      </Box>
  );
};
};
