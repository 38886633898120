import React, { ReactNode,createContext, useReducer, type Dispatch, useContext } from 'react';
//internal imports
import { addSkid, addSkids, removeSkid, removeSkids, clearSkids } from './skidsHelpers';


 //Interfaces
 interface Props {
    children?: ReactNode
    // any props that come into the component
  }
export interface SkidsController{
  Available:Set<string>,
  Skid:string,
  Views:Set<string>
}
export interface Action{
    type:'addSkids' | 'addSkid' | 'removeSkid' | 'removeSkids' | 'clearSkids'
    skids:string[] | null
    skid:string | null
}

export const skidsContext = createContext<Set<string>>(new Set<string>())
const skidsDispatchContext = createContext<Dispatch<any> | null>(null)
//export functions

export function useSkidConfig(){
    return useContext(skidsContext)
  }
  export function useSkidConfigDispatch(){
    return useContext(skidsDispatchContext)
  }

  export default function ConfigProvider({ children }:Props) {
    const [skids, dispatch] = useReducer(SkidsReducer, new Set<string>());
    return (
    <skidsContext.Provider value={skids}>
      <skidsDispatchContext.Provider value={dispatch}>
        {children}
      </skidsDispatchContext.Provider>
    </skidsContext.Provider>
    );
  }
  function SkidsReducer(skids:Set<string>,action:Action){
    switch(action.type){
        case'addSkid':{
            return addSkid(skids,action)
        }
        case 'addSkids':{
            return addSkids(skids,action)
        }
        case 'removeSkid':{
            return removeSkid(skids,action)
        }
        case 'removeSkids':{
            return removeSkids(skids,action)
        }
        case 'clearSkids':{
            return clearSkids(skids)
        }
    }
  }