import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';


export const skidRows = [
    {unit: '#EQN1', type: 'INTEGRATED', client: 'Equinor R&D', location: 'Norway', assetType: 'Onshore', assetName: 'R&D Facility'},
    {unit: '#EQN2', type: 'INTEGRATED', client: 'Equinor R&D', location: 'Norway', assetType: 'Onshore', assetName: 'R&D Facility'},
    {unit: '#1', type: 'FRAMED', client: 'Baker Hughes', location: 'Norway', assetType: 'Onshore', assetName: 'Mixing Facility'},
    {unit: '#2', type: 'FRAMED', client: 'Intelligent Mud Solutions', location: 'Norway', assetType: 'Onshore', assetName: 'Technology Centre'},
    {unit: '#3', type: 'INTEGRATED', client: 'Equinor ASA', location: 'Norway', assetType: 'MODU', assetName: 'Transocean Enabler'},
    {unit: '#3E', type: 'INTEGRATED', client: 'Equinor ASA', location: 'Norway', assetType: 'MODU', assetName: 'Transocean Enabler'},
    {unit: '#4', type: 'FRAMED', client: 'Intelligent Mud Solutions', location: 'Norway', assetType: 'Onshore', assetName: 'Technology Centre'},
    {unit: '#5', type: 'FRAMED', client: 'Baker Hughes', location: 'Norway', assetType: 'Jack Up', assetName: 'Askepott'},
    {unit: '#5E', type: 'FRAMED', client: 'Baker Hughes', location: 'Norway', assetType: 'Jack Up', assetName: 'Askepott'},
    {unit: '#6', type: 'SEMI FRAMED', client: 'Maersk', location: 'Norway', assetType: 'Jack Up', assetName: 'Maersk Integrator'},
    {unit: '#6E', type: 'SEMI FRAMED', client: 'Maersk', location: 'Norway', assetType: 'Jack Up', assetName: 'Maersk Integrator'},
    {unit: '#7', type: 'SEMI FRAMED', client: 'Baker Hughes', location: 'Norway', assetType: 'MODU', assetName: 'Transocean Endurance'},
    {unit: '#8', type: 'INTEGRATED', client: 'ConocoPhillips', location: 'Norway', assetType: 'Platform', assetName: 'Ekofisk X'},
    {unit: '#9', type: 'CONTAINERISED', client: 'ADNOC', location: 'UAE', assetType: 'Artificial Islands', assetName: 'Sateh Al Razboot (SARB)'},
    {unit: '#9E', type: 'CONTAINERISED', client: 'ADNOC', location: 'UAE', assetType: 'Artificial Islands', assetName: 'Sateh Al Razboot (SARB)'},
    {unit: '#10', type: 'FRAMED', client: '', location: 'Norway', assetType: 'MODU', assetName: 'Scarabeo 8'},
    {unit: '#11', type: 'SEMI FRAMED', client: 'Aramco', location: 'Saudi Arabia', assetType: 'Onshore', assetName: 'Ghawar Oil Field'},
]
const TablePage = () => {
    return (
        <Paper style={{width: '80%', height: '400px', overflowX: 'auto'}} elevation={10}>
            <TableContainer component={Paper} style={{width: '100%', height: '100%', backgroundColor: "#0A1929"}} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={6} sx={{color: 'white'}}>
                        <Typography variant='h6'>Skid Overview</Typography>
                    </TableCell>
                    </TableRow>
                <TableRow 
                hover
                >
                    <TableCell align="center" sx={{color: 'white'}}>UNIT No.</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>UNIT TYPE</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>CLIENT</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>CLIENT LOCATION</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>ASSET TYPE</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>ASSET NAME</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {skidRows.map((row, idx) => (
                    <TableRow
                    hover
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{color: 'white'}}>{row.unit}</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>{row.type}</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>{row.client}</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>{row.location}</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>{row.assetType}</TableCell>
                    <TableCell align="center" sx={{color: 'white'}}>{row.assetName}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Paper>
    );
};
export default TablePage;