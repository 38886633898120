//react imports
import React, {useState} from 'react'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ClearIcon from '@mui/icons-material/Clear';
import { useMsal } from "@azure/msal-react";
//mui imports
import { Box,TextField, Paper, FormControl, FormHelperText, Button, MenuList, MenuItem, ListItemText, IconButton } from '@mui/material';
export interface EmailHandler{
   (event:React.MouseEvent<HTMLElement>):void
}
export interface EmailAddHandler{
    (email:string): void
}
interface EmailProps{
    mails:string[],
    emailAddHandler:EmailAddHandler,
    emailRemoveHandler:EmailHandler
}

function EmailForm(props:EmailProps){
    const {mails, emailAddHandler, emailRemoveHandler} = props
    const [email, setEmail] = useState('');
    const sx = {input: { color: 'white' }, color: 'white', borderColor:'white',
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderWidth: "0.5px",
      },"& .MuiInputLabel-outlined": {
        color: "white",
      },

    }
    function handleKeydown(e:React.KeyboardEvent<HTMLDivElement>){
        if (e.key === 'Enter'){
           emailAddHandler(email)
           setEmail('')
        }
    }
    function handleButton(){
        emailAddHandler(email)
        setEmail('')
    }
    return(
        <Box display={"flex"} sx={{flexDirection: 'row', justifyContent: 'space-between',}}>
            <Box display={"flex"}  alignItems="center" justifyContent="flex start">
                <FormControl sx={{ m: 1, minWidth: 140, px:5 }}>
                    <FormHelperText sx={{color: 'white'}}>{'Add E-mail'}</FormHelperText>
                    <TextField id='add-email-text-field' label="Enter E-Mail" variant="outlined" value={email} onChange={e=>setEmail(e.target.value)} onKeyDown={handleKeydown} sx={sx} focused={false}/>
                </FormControl>
                <Button size="small" variant="contained" color="success" onClick={handleButton}>Add</Button>
            </Box>
            <Box>
                <MenuList>
                {mails.map((email:string)=>(
                  <MenuItem>
                  <ListItemText sx={{color: 'white'}}>{email}</ListItemText>
                  <IconButton value={email} aria-label="remove" size="small" onClick={emailRemoveHandler}><ClearIcon fontSize="inherit" sx={{color: 'white'}} /></IconButton>
                  </MenuItem>  
                ))}
               </MenuList>
            </Box>
        </Box>
    )
}

export default EmailForm