//react imports
import React, {useState} from 'react'
import { useMsal } from "@azure/msal-react";
//mui imports
import { Box, Button, Paper, responsiveFontSizes } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
//internal imports
import { loginRequest, protectedResources } from "../../authConfig"
import SelectForm from '../Select/SelectForm';
import { fetchDeafultView, fetchViewConfig, fetchViews, fetchData } from '../../Helper/Fetch/fetch';
import {useConfig, useConfigDispatch} from '../ViewConfig/ConfigProvider'
import { configBuilder } from '../ViewConfig/ConfigBuilder';
import DateForm from '../Select/DateForm';
import validateHistoricFetch from './validateHistoricFetch';

//Type Definitons
interface SelectorProps{
    skids: Set<string>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    dataHandler: ResponseHandler,
    setSkid: React.Dispatch<React.SetStateAction<string>>,
    skid:string
    setStart: React.Dispatch<React.SetStateAction<number>>,
    start:number
    setEnd: React.Dispatch<React.SetStateAction<number>>,
    end:number
    setMessage:React.Dispatch<React.SetStateAction<string>>,
    
}
export interface ResponseHandler{
    (resp:Promise<Response>): void
}
export interface HandlerFunc{
    (event:React.ChangeEvent<HTMLInputElement>): void
}
//Helper functions
function getAggregateOptions(start:number, end:number):number[]{
    const delta = end-start
    const day = 8640
    const minute = 60
    const hour = 3600
    if (delta >= day) {
        return [5*minute, 30*minute, hour, 2*hour]
    }
    return [5,30,minute, 5*minute]
}

//Start of component
function HistoricSelector(props:SelectorProps){
//constants
    const { skids, setLoading, dataHandler, setSkid, skid, start, setStart, end, setEnd, setMessage } = props
    const { instance, accounts, inProgress } = useMsal();
    const viewConfig = useConfig();
    const viewConfigDispatch =useConfigDispatch();
    const request = {scopes: protectedResources.whoami.scopes,account: accounts[0]}
    const [skidViews, setSkidViews] = useState(['']);
    const [aggregation,setAggregation] = useState(60)

    const aggeregateOptionText = (option:number)=>{
        if (option>3600){
            const text = `${option/3600} Hours`
            return text
        }else if(option===3600){
            const text = `${option/3600} Hour`
            return text
        }else if (option>60){
            const text = `${option/60} Min`
            return text
        }else{
            const text = `${option} Sec`
            return text
        }
    }
    
//Handlers 
    function StartSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        console.log("start time:",event.target.value, typeof(event.target.value))
        setStart(Date.parse(event.target.value))
        
    }
    function EndSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        console.log("end time:",event.target.value,typeof(event.target.value))
        setEnd(Date.parse(event.target.value))
    }

    function ConfigSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        fetchViewConfig(request,instance,skid,event.target.value).then(res => res.json()).then((items) =>{
            viewConfigDispatch({
                type:'updateConfig',
                chart: null,
                category: null,
                config: configBuilder(items),
            })
        })
    }

    function SkidSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        fetchDeafultView(request,instance,event.target.value).then(res => res.json()).then((items) =>{
            viewConfigDispatch({
                type:'updateConfig',
                chart: null,
                category: null,
                config: configBuilder(items),
              })
        })
       fetchViews(request,instance,event.target.value).then(res => res.json()).then((items)=>{
            setSkidViews(items.Views)
       })
       setSkid(event.target.value)
    }
    function AggregationSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        setAggregation(parseInt(event.target.value))
    }
    function fetchHandler(){
        const options = {start: start, end: end, skid: skid, view: viewConfig.charts, aggregation:aggregation}
        const msg = validateHistoricFetch(options)
        if (msg==='ok'){
            setLoading(true)
            let resp = fetchData(request, instance, options)
            dataHandler(resp)
        }else{
            setMessage(msg)
        }
        
          
    }
return(
    <Paper className='Selector-Paper' key='Selector' elevation={8} sx={{backgroundColor:'#273746', width: '100%', py:'15px'}} >
       <Box display="flex" justifyContent="space-around" alignItems="center">
            <SelectForm title='Skid' options={Array.from(skids)} selected={skid} changeHandler={SkidSelectHandler} />
            <SelectForm title='View' options={skidViews} selected={viewConfig.name} changeHandler={ConfigSelectHandler} />
            <DateForm title='Time' startHandler={StartSelectHandler} endHandler={EndSelectHandler} startValue={start} endValue={end} />
            <SelectForm title='Aggregation' options={getAggregateOptions(start,end)} selected = {aggregation} changeHandler={AggregationSelectHandler} optionDisplay={aggeregateOptionText} />
            <Button variant="contained" color="success" onClick={fetchHandler}>Get Data</Button> 
        </Box>
    </Paper>
    )
}

export default HistoricSelector