import Features from '../components/Features';
import Box from '@mui/material/Box';


const Welcome = () => {
    return (
        <Box sx={{width:'100%', height:'100%', display: 'flex', justifyContent: 'center', alignContent: 'center', mt: 30}}>
            <Features />
        </Box>
    );
};
export default Welcome; 