import React, {} from 'react'

import { Box, Paper, CircularProgress } from '@mui/material';

import { CSVLink } from "react-csv";

import ErrorBox from '../ErrorBox';
import { DataPoint } from '../../Pages/Historic';
import ChartView from './ChartView';



//Type Definitions
interface PresenterProps{
    data: DataPoint[]
    error:any
    loading:boolean
    skid:string
    start:number
    end:number
    message:string
}
/* function prepForCSV(data:DataPoint[]){
    const result = []
    for (let dp of data){
        const date = new Date(dp['timestamp'])
        let hrfDP:{[index:string]:number|string} = dp
        hrfDP['timestamp']= date.toLocaleString()
        result.push(hrfDP)
    }
    return result 
}*/
function toCSV(data:DataPoint[]){
    const result = data.map(dp=>{
        let ndp:{[index:string]:number|string} ={...dp}
        const date = new Date(ndp['timestamp'])
        ndp['timestamp']= date.toLocaleString()
        return ndp
    })
    return result
}
function createFileName(data:DataPoint[],skid:string){
    const start = new Date(data[0]['timestamp'])
    const end = new Date(data[data.length-1]['timestamp']) 
    const filename = skid+'-'+start.toLocaleTimeString()+'-'+end.toLocaleTimeString()
    return filename
}
function HistoricPresenter(props:PresenterProps){
    const {data, error, loading, skid, start, end, message} = props
    
    console.log('data:',data)
    if(error !== null){
        return <ErrorBox error={error}/>
    }
    if(loading){
        return <CircularProgress color='primary' size={50}/>   
    }
    if(data.length <2){
        
        return (
        <Paper elevation={10} sx={{backgroundColor:'#273746', width: '100%', mt:'30px',  py:'15px'}}  >
            <h3> {message} </h3>
        </Paper>)
    }
    return(
        <Box className='Presentation-Window' sx={{ display:'inline-block', rowGap:1}}>
            <Paper className='ChartsPaper'elevation={10}  sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', columnGap:1, justifyContent:'center', pb:'30px', mt: '30px', backgroundColor:'#273746'}} >
              <ChartView data={data} loading ={loading} start={start} end={end}/>  
            </Paper>
            <CSVLink
                    data={toCSV(data)}
                    filename={createFileName(data,skid)}
                    className="btn btn-success"
                    target="_blank"
                >
                Download CSV
            </CSVLink>;
        </Box>
    )
}

export default HistoricPresenter