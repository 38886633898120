import React,{} from 'react'
import { MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { BootstrapInput, MyIcon, useStyles } from '../TargetForm.js';

export interface SelectHandler{
    (event:React.ChangeEvent<HTMLInputElement>): void
}
export interface optionDisplayFunc{
    
}
interface SelectProps{
    title:string,
    selected:string|number|null
    options:number[]|string[]
    changeHandler:SelectHandler
    optionDisplay?:(option:number|string)=>string
}
const optionText = (option:number|string)=>{
      return(option.toString())
    }

function SelectForm(props:SelectProps){
    const { title, selected, options, changeHandler, optionDisplay=optionText} = props
    const classes = useStyles();
    const titleLabel = `${title} Select`
    const SelectLabelId = `${title}-select-small-label`
    const SelectId = `${title}-select-small`
    const FormOptions = options.map(option => {
        return (
            <MenuItem key={option.toString()} value={option}> {optionDisplay(option)} </MenuItem>
        )  
      });
    if (selected==null){
        return(<></>)
      }
      return(
        <FormControl sx={{ m: 1, minWidth: 180, px:5 }}>
          <FormHelperText sx={{color: 'white'}}>{title}</FormHelperText>
          <Select
            sx={{backgroundColor: '#273746'}}
            MenuProps={{
              classes: {
                paper: classes.select,
              }
            }}
            labelId={SelectLabelId}
            defaultValue={`Select ${title}`}
            id={SelectId}
            value={selected}
            label={title}
            onChange={changeHandler}
            input={<BootstrapInput />}
            inputProps={{classes: {input: classes.input, icon: classes.icon}}}
            IconComponent={MyIcon}
          >
          {FormOptions}
          </Select>
        </FormControl>
      ) 
    

}

export default SelectForm