export function AxisTicks(axis, number){
        if (axis != undefined){
            let ticks = []
            let start = axis[1]
            let end = axis[0]
            let difference = end-start
            let delta = difference/ (number-1)
            for(let i = 0; i<number-1; i++){
                ticks.push(start+delta*i)
            }
            ticks.push(end)
            return ticks
        }
        
        return axis
  
}
export default AxisTicks;