import { useState, useEffect } from 'react';
import { Box, Stack, Button, Paper, Typography, TextField, FormControl, FormHelperText, MenuItem, 
    Select, Switch, CircularProgress, Divider, Checkbox, Radio, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useMediaPredicate } from "react-media-hook";
import Link from '@mui/material/Link';
import { BootstrapInput, MyIcon, useStyles } from './TargetForm.js';
import DownloadIcon from '@mui/icons-material/Download';
import { Document, Page, pdfjs  } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { makeStyles } from '@material-ui/core/styles';
import SkidRows, { skidRows } from '../Pages/TablePage';
import { useMsal } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import { buildDateTime } from './Report/buildDateTime.ts';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles1 = makeStyles((theme) => ({
    divider: {
      background: 'white',
    },
}));

const GenerateReportForm = () => {
    const { instance, accounts, inProgress } = useMsal();
    const classes = useStyles();
    const [dateValue, setDateValue] = useState(null);
    const [startTimeValue, setStartTimeValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [endTimeValue, setEndTimeValue] = useState(null);
    const [emails, setEmails] = useState(['', '', '', '', '', '', '', '']);
    const [switchStatus , setSwitchStatus] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [downloadURL, setDownloadURL] = useState(null);
    const [uint8Array, setUin8tArray] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [buffer, setBuffer] = useState(null);
    const moreThan720 = useMediaPredicate("(min-width: 720px)");
    const [resampleRate, setResampleRate] = useState(60);
    const [skid, setSkid] = useState(null);
    const [availableSkids, setAvailableSkids] = useState(null);
    const [radioOption, setRadioOption] = useState(null);
    const [skidOptions, setSkidOptions] = useState(null);

    const TimeOptions = [60, 120, 300, 600, 1800].map(option => {
        return (
            <MenuItem key={option.toString()} value={option}>{option/60} minutes</MenuItem>
        );
    });
    

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

    const fetchAvailableSkids = async () => {
        const request = {
          scopes: protectedResources.whoami.scopes,
          account: accounts[0],
        };
        instance.acquireTokenSilent(request).then((response) => {
          const headers = new Headers();
          const bearer = response.accessToken;
          const end = new Date().toISOString();
          headers.append("Authorization", bearer);
          const options = {
            method: "GET",
            headers: headers
          };
          const url = new URL("https://rheoport-api.azurewebsites.net/GetAvailableSkids");
          //const url = new URL("http://localhost:5000/GetAvailableSkids");
          fetch(url, options)
          .then(res => res.json())
          .then((items) =>{
            console.log("available skids: ", items.skids);
            setAvailableSkids(items.skids);
      })
      .catch(error => {
        console.log(error);
        setError('No skids available skids found for this user.');
      });
      })
    };

    const changeEmail = (e,num) => {
        let newEmail = emails.slice();
        newEmail[num] = e.target.value;
        setEmails(newEmail);
    };
    const handleClick = (e) => {
        setResampleRate(e.target.value);
    };
    const handleRadioChange = (e) => {
        if (e.target.value=='null') {
            setRadioOption(null);
        } else if (e.target.value=='00') {
            setRadioOption(1);
        } 
        else{
            setRadioOption(parseInt(e.target.value));
        }
        
    }
    const emailForm = emails.map((item, index) => {
        return (
        <FormControl sx={{mr: 2, mt: 2}} variant="standard" key={index}>
            <TextField key={index} id="email1" label={`Email #${index+1}`} fullWidth  variant='outlined' value={item} onChange={(e) => changeEmail(e, index)} 
            sx={{
                svg: { color: 'white !important' },
                input: { color: 'white !important' },
                label: { color: 'white !important' },
                outlinedInput: { borderColor: 'white !important' },
                fieldset: { borderColor: 'white !important' },
                minWidth: '270px'
              }}
              />    
        </FormControl>
        );
    });
    const GenerateReportAsync = async (start, end, emails, emailStatus) => {
        const request = {
            scopes: protectedResources.whoami.scopes,
            account: accounts[0]
          };
        instance.acquireTokenSilent(request).then((response) => {
          const headers = new Headers();
          const bearer = response.accessToken;
          headers.append("Authorization", bearer);
        //const url = new URL("https://rheoport-api.azurewebsites.net/SendReport");
        const url = new URL("http://localhost:5000/SendReport");
        url.search = new URLSearchParams({
          start: start,
          end: end,
          emailStatus: emailStatus,
          emails: emails,
          resample_rate: resampleRate,
          skid:skid,
          length:radioOption
          
        });
        
        
        fetch(url,{mehtod: "GET", headers:headers})
        .then(res => {
            if (res.status >= 400){
                throw new Error("Server Encoutered a problem while generating PDF")
            }
            return res.blob()
        })
        .then((blob) =>{
            console.log("blob:",blob)
            if(blob){
                blob.arrayBuffer().then(buffer => {
                    setBuffer(buffer);
                })
                setUin8tArray(blob);
                setIsLoading(false);
            }
        
        })
        .catch(error => {
            console.log(Error)
            setError(error.message);
            setIsLoading(false)
        });
    })    
    };
    useEffect(()=>{
        fetchAvailableSkids()
    },[])

    useEffect(() =>{
        if (availableSkids) {
          setSkid(availableSkids[0])
          const skids = availableSkids.map(option => {
            console.log(option)
            return (
              <MenuItem key={option.toString()} value={option.toString()}>{option} </MenuItem>
            )
          });
          setSkidOptions(skids);
        }
      }, [availableSkids]);

    useEffect(()=>{
        console.log("creating url link")
        if(uint8Array){
            setDownloadURL(window.URL.createObjectURL(uint8Array))
        }
        else{
            console.log("blob was null")
        }
    },[uint8Array]);
    useEffect(()=>{
        if(downloadURL){
            console.log("Download url: ", downloadURL)
        }
    },[downloadURL])
        
    function allAreEmpty(arr) {
        return arr.every(element => element === '');
        };

    const sendForm = () => {
        if (!dateValue && radioOption === null) {
            setError('Please select a date');
            return;
        }
        if (emails.length < 1 && switchStatus) {
            setError('Please enter at least one email');
            return;
        }
        if (allAreEmpty(emails) && switchStatus) {
            setError('Please enter at least one email');
            return;
        }
        if (!endDateValue && radioOption === null) {
            setError('Please select an end date');
            return;
        }
        if (dateValue != null && startTimeValue === null) {
            setError('Please select a start timestamp');
            return;
        }
        if (endDateValue != null && endTimeValue === null) {
            setError('Please select an end timestamp');
            return;
        }
        setError('');
        setIsLoading(true);
        if (radioOption != null) {
            if(radioOption == 1) {
                let start = new Date();
                let end = new Date();
                start.setDate(start.getDate()-1);
                start.setHours(0,0,0,0);
                end.setDate(end.getDate()-1);
                end.setHours(23,59,59,999);
                GenerateReportAsync(start, end, emails, switchStatus);
            }
            let start = Date.now()-(1000*60*60*radioOption);
            let end = Date.now();
            start = new Date(start).toISOString();
            end = new Date(end).toISOString();
            GenerateReportAsync(start, end, emails, switchStatus);
        }else if(radioOption==null){
            console.log('generating custom timeformat report')
            // time picker returns stupid object hence $d to access the underlying date object
            const start = buildDateTime(dateValue,startTimeValue.$d).toISOString()
            const end = buildDateTime(endDateValue,endTimeValue.$d).toISOString()
            console.log(start)
            console.log(end)
            GenerateReportAsync(start, end, emails, switchStatus)
        } else {
            let start = dateValue.setHours(0,0,0,0);
            let end = dateValue.setHours(23,59,59,999);
            start = new Date(start).toISOString();
            end = new Date(end).toISOString();
            GenerateReportAsync(start, end, emails, switchStatus);
        }
    };
    console.log(radioOption);
    return (
        <Box sx={{mt:3, display: 'flex', flexDirection: 'column', width: '45vw', justifyContent: 'center', alignItems: 'center'}}>
            <Paper elevation={4} sx={{pb:5,justifyContent:'center', alignItems:'center', display: 'flex', flexDirection: 'column', backgroundColor:'#273746', width: '100%'}}>
                <Box>
                    <Typography variant="subtitle" component="h3" sx={{color: 'white', mt:1}} >Standard Time Settings</Typography>
                </Box>
                <Box>
                <Stack spacing={10} direction='row' sx={{mt:5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <FormControl sx={{width:'100%'}}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={radioOption}
                        onChange={handleRadioChange}
                        sx={{display: 'flex', justifyContent: 'space-evenly'}}
                    >
                        <FormControlLabel sx={{color:'white'}} value="null" control={<Radio sx={{color:'white'}} />} label="None" />
                        <FormControlLabel sx={{color:'white'}} value="24" control={<Radio sx={{color:'white'}} />} label="past 24 hours" />
                        <FormControlLabel sx={{color:'white'}} value="12" control={<Radio sx={{color:'white'}} />} label="past 12 hours" />
                        <FormControlLabel sx={{color:'white'}} value="8" control={<Radio sx={{color:'white'}} />} label="past 8 hours" />
                        <FormControlLabel sx={{color:'white'}} value="00" control={<Radio sx={{color:'white'}} />} label="past 24 hours from midnight" />
                        
                    </RadioGroup>
                </FormControl>
                </Stack>
                </Box>
            </Paper>
            <Paper elevation={4} sx={{pb: 5, mt:5,justifyContent:'center', alignItems:'center', display: 'flex', flexDirection: 'column', backgroundColor:'#273746', width: '100%'}}>
            <Box>
                <Typography variant="subtitle" component="h3" sx={{color: 'white', mt:3}} >Select Specific Time Interval</Typography>
            </Box>
            <Stack spacing={5} direction='row' sx={{mt:5, justifyContent: 'center', alignItems: 'center'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Select start date"
                        disableFuture
                        minDate={new Date(Date.now()- 1000*60*60*24*7)}
                        value={dateValue}
                        onChange={(newValue) => {
                        setDateValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} 
                        sx={{
                            svg: { color: 'white !important' },
                            input: { color: 'white !important' },
                            label: { color: 'white !important' },
                            outlinedInput: { borderColor: 'white !important' },
                            fieldset: { borderColor: 'white !important' },
                          }}
                        />}
                        toolbarTitle="Select start date"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label="Select start time"
                        value={startTimeValue}
                        onChange={(newValue) => {
                        setStartTimeValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} 
                        sx={{
                            svg: { color: 'white !important' },
                            input: { color: 'white !important' },
                            label: { color: 'white !important' },
                            outlinedInput: { borderColor: 'white !important' },
                            fieldset: { borderColor: 'white !important' },
                          }}
                        />}
                        toolbarTitle="Select start time"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Select end date"
                        disableFuture
                        minDate={dateValue === null ? new Date(Date.now()- 1000*60*60*24*7) : dateValue}
                        value={endDateValue}
                        onChange={(newValue) => {
                        setEndDateValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} 
                        sx={{
                            svg: { color: 'white !important' },
                            input: { color: 'white !important' },
                            label: { color: 'white !important' },
                            outlinedInput: { borderColor: 'white !important' },
                            fieldset: { borderColor: 'white !important' },
                          }}
                        />}
                        toolbarTitle="Select end date"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label="Select end time"
                        minTime={(dateValue === endDateValue) ? startTimeValue : null }
                        value={endTimeValue}
                        onChange={(newValue) => {
                            console.log('setting')
                        setEndTimeValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} 
                        sx={{
                            svg: { color: 'white !important' },
                            input: { color: 'white !important' },
                            label: { color: 'white !important' },
                            outlinedInput: { borderColor: 'white !important' },
                            fieldset: { borderColor: 'white !important' },
                          }}
                        />}
                        toolbarTitle="Select end time"
                    />
                </LocalizationProvider>
                </Stack>
                </Paper>
                <Paper elevation={4} sx={{pb: 5, mt:5,justifyContent:'center', alignItems:'center', display: 'flex', flexDirection: 'column', backgroundColor:'#273746', width: '100%'}}>
                <Box>
                    <Typography variant="subtitle" component="h3" sx={{color: 'white', mt:3}} >Configuration</Typography>
                </Box>
                <Stack direction="row" spacing={5} sx={{mt:3, justifyContent: 'center', alignItems: 'center'}}>
                <FormControl sx={{minWidth: 200, pr:2}}>
                    <FormHelperText sx={{color: 'white'}}>Select Resample Rate</FormHelperText>
                    <Select
                    sx={{backgroundColor: '#273746'}}
                    MenuProps={{
                        classes: {
                        paper: classes.select,
                        }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={resampleRate}
                    onChange={handleClick}
                    input={<BootstrapInput />}
                    inputProps={{classes: {input: classes.input, icon: classes.icon}}}
                    IconComponent={MyIcon}
                    
                    >
                    {TimeOptions}
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 200, pr:2}}>
                    <FormHelperText sx={{color: 'white'}}>Select Skid</FormHelperText>
                    <Select
                    sx={{backgroundColor: '#273746'}}
                    MenuProps={{
                        classes: {
                        paper: classes.select,
                        },
                        PaperProps: { 
                            sx: { maxHeight: 250 } 
                        },
                    }}
                    labelId="skid"
                    id="skid"
                    value={skid}
                    onChange={((newValue) => {console.log(newValue.target.value)
                        setSkid(newValue.target.value)})}
                    input={<BootstrapInput />}
                    inputProps={{classes: {input: classes.input, icon: classes.icon}}}
                    IconComponent={MyIcon}
                    
                    >
                    {skidOptions}
                    </Select>
                </FormControl>
                </Stack>
                </Paper>
                <Paper elevation={4} sx={{pb: 5, mt:5,justifyContent:'center', alignItems:'center', display: 'flex', flexDirection: 'column', backgroundColor:'#273746', width: '100%'}}>
                <Box>
                    <Typography variant="subtitle" component="h3" sx={{color: 'white', mt:3}} >Email recipients</Typography>
                </Box>
                <Stack spacing={5} direction='row' sx={{mt:2, justifyContent: 'center', alignItems: 'center'}}>
                    <>
                    <Box>
                        {emailForm}
                    </Box>
                    </>  
                </Stack>
                <Stack spacing={5} direction='row' sx={{mt:2, justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{color: 'white'}}>Send Email</Typography>
                        <Checkbox label="Send Email" color="success" sx={{color: 'white'}} onClick={() => setSwitchStatus(!switchStatus) } />
                    </Box>
                </Stack>
                </Paper>
            <Typography variant="body2" component="p" color="error">{error}</Typography>
            {!isLoading &&
            <Button variant="contained" color="primary" sx={{mt: 5, minWidth: 170, height: 50}}> <Typography variant="p" component="p" onClick={() => sendForm()} > Generate Report</Typography> </Button>
            }
            {isLoading && 
            <Button variant="contained" color="primary" sx={{mt: 5, minWidth: 170, height: 50}}> <CircularProgress size={28} sx={{color: 'white'}} /> </Button>
            }
            {uint8Array &&
            <Box sx={{display:'flex', flexDirection:'column', flexWrap: 'wrap', mt: 5}}>
                <Paper elevation={10} sx={{mb: 2, backgroundColor: '#273746', py: 1}}>
                    <Stack direction="row" spacing={2} sx={{justifyContent: 'space-evenly', mx: 1}}>
                        <Box>
                            <Typography variant="p" component="p" sx={{color:'white', fontSize: {xs: '0.8em', md: '1.2em'}}}>RheoPort #1 </Typography>
                        </Box>
                        <Box>
                            <Typography variant="p" component="p" sx={{color:'white', fontSize: {xs: '0.8em', md: '1.2em'}}}>Date: 2022-06-01 </Typography>
                        </Box>
                        <Box>
                            <Link href={downloadURL} underline="none" download><Typography variant="p" component="p" sx={{color:'white', fontSize: {xs: '0.8em', md: '1.2em'}}}> <DownloadIcon /> Download pdf</Typography></Link>
                        </Box>
                    </Stack>
                </Paper>
                {moreThan720 ? (
                <Stack direction="row" spacing={4} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>   
                        <Document file={uint8Array} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={1} width={650} height={650} />
                        </Document>
                    </Box>
                    <Box>
                        <Document file={buffer} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={2} width={650} height={650} />
                        </Document>
                    </Box>
                </Stack>
                )
                : (
                    <Stack direction="column" spacing={5}>
                    <Box>   
                        <Document file={uint8Array} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={1} width={350} height={350} />
                        </Document>
                    </Box>
                    <Box>
                        <Document file={buffer} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={2} width={350} height={350} />
                        </Document>
                    </Box>
                </Stack>
                )
            }
            </Box>
            }
        </Box>
    );
};
export default GenerateReportForm;