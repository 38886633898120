import React, {} from 'react'
import {  Typography, Box } from '@mui/material';
interface ErrorProps{
    error:any
}
function ErrorBox(props:ErrorProps){
    const {error} = props
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant='h5' component="h5" color="error.main" >{error.message}</Typography>
        </Box>
      )
}

export default ErrorBox
