
import { Chart, Category } from "../../components/ViewConfig/ConfigProvider";
import GroupHasData from "../GroupHasData";

export interface Stats{
    Count:number,
    Min:number,
    Max:number,
    Sum:number
}
export interface StatsCollection{
    items:[],
    [index:string]:Stats|[]
}

function isStats(property:Stats | []): property is Stats{
    return (property as Stats).Count !== undefined
}
function chartHasData(chart:Chart, stats:StatsCollection):boolean{
    let hasData = false
    //console.log(`Checking if ${chart.name} has data`)
    //console.log('stats:',stats)
    chart.categories.forEach((category:Category, key:string):void =>{
           console.log(`Checking if ${category.name} with key ${category.datakey} has data`)
            const datakey = category.datakey
            let property = stats[datakey]
            console.log(property)
            if(property){
                
            let categoryStats = property as Stats 
            if (categoryStats.Count){
                let dataInCategory = false
      //          console.log(categoryStats.Count)
                if(categoryStats.Count>3){
          //          console.log('hello')
                    dataInCategory = true
        //            console.log(`${category.name} has data`)
                }
                hasData = hasData || dataInCategory
            }  }
    });
   // console.log(`${chart.name} has data`,hasData)
    return hasData
}

export function isChartActive(chart:Chart, stats:StatsCollection){
   
    
    let isEnabled = false
    if(!stats){
        return isEnabled
    }
    if(!chart){
        return isEnabled
    }
    if(!chart.enabled){

        return isEnabled
    }
    chart.categories.forEach((category:Category, key:string):void=>{
        isEnabled = isEnabled || category.enabled
    });
    //console.log('isEnabled:',isEnabled)
    const hasData = chartHasData(chart,stats)
    //console.log('hasData:',hasData)
    const isActive = isEnabled && hasData
    //console.log('active:',isActive)
    return isActive
}