import { log } from "console"

export default function createDisplayName(tag:string):string{
    const tagParts = tag.split('_')
    if(tagParts.length == 1){
        if (tagParts[0] == 'PV'){
            return 'Plastic Viscosity'
        }
            return 'Yield Point'
    }
    else if (tagParts[tagParts.length -1] == 'PPG'){
        if (tagParts[tagParts.length -3] == 'vs'){
            return 'Density Vertical ' + 'PPG'
        }
            return 'Density Coriolis ' + 'PPG'
    }else if(tagParts[tagParts.length -1] == 'm'){
        if(tagParts[1] == 'f'){
            return 'Temperature (F)'
        }
        if(tagParts[0] != 'rho'){
            return 'Temperature (C)'
        }
            return 'Density Coriolis'
    }else{
        if(tagParts[1]=='vs'){
            return 'Density Vertical'
        }if(tagParts[1]=='api'){
            if(tagParts[0] == 'my'){
                if(tagParts[2] == 'pv'){
                    return 'Plastic Viscosity api'
                }
                    return 'Yield Point api'
            }
            return tagParts[2].slice(0,-3)+' RPM api'
        }if(tagParts[1]=='api150'){
            if(tagParts[0] == 'my'){
                if(tagParts[2] == 'pv'){
                    return 'Plastic Viscosity api 150'
                }
                    return 'Yield Point api 150'
            }
            return tagParts[2].slice(0,-3) +' RPM api 150'
        }
        else{
            
            return tagParts[1].slice(0,-3) +' RPM'
        }
    }
}