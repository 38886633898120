export  function AxisFromStats(stats, state){
    function getMin(vertMin,coreMin){
        if(vertMin <= coreMin){
            return vertMin
        }
        return coreMin
    }
    function getMax(vertMax,coreMax){
        if(vertMax >= coreMax){
            return vertMax
        }
        return coreMax
    }
    function getDensityAxis(vertMin,vertMax, coreMin, coreMax){
        return [getMin(vertMin,coreMin)*0.9,getMax(vertMax,coreMax)*1.15]
    }
    function defineAxis(min, max, category){
        let delta = max-min
        if (category === 'rho_e_m' || category === 'rho_vs_e') {
            if (delta < 0.01) {
                let axis = [min - (0.2*max), max + (0.2*max)]
                return axis
            } else {
                let localMin = min - (0.5*delta);
                let localMax = max + (0.5*delta);
                if (localMin < 0) {
                    localMin = 0;
                };
                let axis = [localMin, localMax]
                return axis
            }
        }
        if (delta < 1){
            let axis = [Math.floor(min), Math.ceil(max)]
            return axis 
        }else {
            let axis = [Math.floor(min/10)*10,Math.ceil(max/10)*10]
        return axis
        }
        
      }

    function getCategoryKeys(stats, state){
        const keys = Object.keys(stats);
        const realKeys = [];
        state.data.map(group => {
            group.categories.map(category => {
                for (let key of keys) {
                    if (key === category.datakey) {
                    realKeys.push(key);
                }
            }
        })
        });
        return realKeys
      }

    const realKeys = getCategoryKeys(stats,state)
    
    let XAxes = {}
    
    for (let i = 3; i < realKeys.length-1; i+=2){
        if(stats.Min!=NaN || stats.Max!=NaN){
            let globalMin = 0;
            let globalMax = 0;
            
            if (stats[realKeys[i]].Min <= stats[realKeys[i+1]].Min){
                globalMin = stats[realKeys[i]].Min;

            } 
            if (stats[realKeys[i]].Min >= stats[realKeys[i+1]].Min){
                globalMin = stats[realKeys[i+1]].Min;

            }
            if (stats[realKeys[i]].Max >= stats[realKeys[i+1]].Max){
                globalMax = stats[realKeys[i]].Max;

            } 
            if (stats[realKeys[i]].Max <= stats[realKeys[i+1]].Max){
                globalMax = stats[realKeys[i+1]].Max;

            }
            
            XAxes[realKeys[i]] = defineAxis(globalMin, globalMax, realKeys[i]);
            XAxes[realKeys[i+1]] = defineAxis(globalMin, globalMax, realKeys[i+1]);
        
        }
    };
    for (let category of Object.keys(stats)){
        let vertDens = stats['rho_vs_e']
        let coreDens = stats['rho_e_m']
        let vertMin = 0
        let vertMax = 0
        let coreMin = 0
        let coreMax = 0
        if(vertDens){
            vertMin = stats['rho_vs_e'].Min
            vertMax = stats['rho_vs_e'].Max
        }
        if(coreDens){
            coreMin = stats['rho_e_m' ].Min
            coreMax = stats['rho_e_m' ].Max
        }
        
        if (category === 't_inlet_m'){
            XAxes[category] = defineAxis(stats[category].Min, stats[category].Max, category);
            XAxes[category] = [XAxes[category][0]*0.8,XAxes[category][1]]
        }
        if (category === 'rho_vs_e' ){
            XAxes[category] = getDensityAxis(vertMin,vertMax,coreMin,coreMax)
        }
        if (category === 'rho_e_m' ){
            XAxes[category] = getDensityAxis(vertMin,vertMax,coreMin,coreMax)
        }
    };

    return XAxes

    };

export default AxisFromStats;