import { DisplayConfig, Chart } from "../ViewConfig/ConfigProvider";

interface HistoricFetchOptions{
    start: number;
    end: number;
    skid: string;
    view: Map<string,Chart>;
    aggregation: number;
}

function validateHistoricFetch(o:HistoricFetchOptions){
    var error:string
    if (o.start>o.end){
        error = `Start ${new Date(o.start).toLocaleString()} is greater than End ${new Date(o.end).toLocaleString()}`
        return error
    }
    if(o.skid==''){
        error= 'No Skid selected'
    }
    if(o.view==null){
        error='No view selected'
    }
    error ='ok'
    return error
}

export default validateHistoricFetch