import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import {MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { BootstrapInput, MyIcon, useStyles } from '../TargetForm.js';

function SelectForm(props) {
    const classes = useStyles();
    const optionText = (option)=>{
      return(option.toString())
    }
    const { title, selected, options, changeHandler, optionDisplay=optionText} = props
    const FormOptions = options.map(option => {
      return (
          <MenuItem key={option.toString()} value={option}> {optionDisplay(option)} </MenuItem>
      )  
    }); 
    const inputLabelId = `${title}-input-select-small-label`
    const SelectLabelId = `${title}-select-small-label`
    const SelectId = `${title}-select-small`
    if (selected==null){
      return(<></>)
    }
    return(
      <FormControl sx={{ m: 1, minWidth: 140, px:5 }}>
        <FormHelperText sx={{color: 'white'}}>{title}</FormHelperText>
        <Select
          sx={{backgroundColor: '#273746'}}
          MenuProps={{
            classes: {
              paper: classes.select,
            }
          }}
          labelId={SelectLabelId}
          id={SelectId}
          value={selected}
          label={title}
          onChange={changeHandler}
          input={<BootstrapInput />}
          inputProps={{classes: {input: classes.input, icon: classes.icon}}}
          IconComponent={MyIcon}
        >
        {FormOptions}
        </Select>
      </FormControl>
    )
}

export default SelectForm