import { ChartIcon } from "@fluentui/react-icons-mdl2"
import { DisplayConfig } from "../../components/ViewConfig/ConfigProvider"
import { isChartActive, StatsCollection }from "./chartIsActive"
import { DataPoint } from "../../Pages/Historic"


export function determineYaxisOrientation(index:number, numActive:number):'right'|'left'{
    if (index==0){
        return 'left'
    }
    if(index==numActive-1 ){
        return 'right'
    }
    return 'left'
}

export function isAxisHidden(index:number, numActive:number):boolean{
    if (index == 0 || index==numActive-1){
        return false
    }
    return true
}
export function numActiveCharts(config:DisplayConfig, stats:StatsCollection):number{
    let activeCharts = 0
    Array.from(config.charts).forEach((chart)=>{
        if(isChartActive(chart[1], stats)){
            activeCharts++
        }
    })
    return activeCharts   
}
export function createYAxis(data:DataPoint[]){
    const first = data[0]['timestamp']
    const last  = data[data.length]['timestamp']
}