import React, {useState} from 'react'

import { Box, Paper, CircularProgress, Stack } from '@mui/material';
import ErrorBox from '../ErrorBox';
// @ts-ignore
import { Document, Page, pdfjs  } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//Type Definitions
interface PresenterProps{
    data: ArrayBuffer
    error:any
    loading:boolean
    message:string
}


function ReportPresenter(props:PresenterProps){
    const {data, error, loading, message} = props
    const [numPages, setNumPages] = useState(0);
    let width = 850
   
    
    function onDocumentLoadSuccess({ numPages }:{numPages:number}) {
        setNumPages(numPages);
      }
    if(error !== null){
        return <ErrorBox error={error}/>
    }
    if(loading){
        return <CircularProgress color='primary' size={50}/>   
    }
    if(!data){
        return (
            <Paper elevation={10} sx={{backgroundColor:'#273746', width: '100%', mt:'30px',  py:'15px'}}  >
                <h3 color='white'> {message} </h3>
            </Paper>
            )
    }
    return(
        <Box className='Presentation-Window' sx={{display:'flex', flexWrap: 'wrap', mt: 5, justifyContent: 'center'}}>
            <Paper elevation={10} sx={{mb: 2, backgroundColor: '#273746', p: 1}}>
                    <Box>   
                        <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={1} width={width} height={width} />
                        </Document>
                    </Box>

            </Paper> 
        </Box>
    )
}

export default ReportPresenter