import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from '@mui/material/Button';
import { SigninIcon } from '@fluentui/react-icons-mdl2';
function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const AadSignin = () => {
    const { instance } = useMsal();

    return (
        <Button variant="contained" component="label" startIcon={<SigninIcon />} onClick={() => handleLogin(instance)}>Sign in using Outlook</Button>
    );
}