import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { globalStateContext, dispatchStateContext } from '../App.js';
import { Typography, Badge, Tooltip } from '@mui/material';

function handleClick(popupstate) {
    popupstate.close();
}
const useStyles = makeStyles((theme) => ({
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "#0A1929"
      }
    }
  }));
  
const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
];
const NotificationsBell = (props) => {
    const { notifiCount } = {...props};
    const classes = useStyles();
    const [state, dispatch] = useGlobalState();

    const removeCount = () => {
        state.config.notifications.clearedCount = 0;
    };
    return (
        <div onClick={removeCount}>
        <PopupState variant="popover" popupId="Notifications-popup">
        {(popupState) => (
            <React.Fragment>
            <Tooltip title="notifications">
            <IconButton variant="contained" sx={{p:0}} {...bindTrigger(popupState)}>
                <Badge badgeContent={state.config.notifications.clearedCount} color="error" sx={{p:0}}>
                    <NotificationsIcon fontSize="large" sx={{color: 'white', p:0}}/>
                </Badge>
            </IconButton>
            </Tooltip>
            { <Menu 
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={classes.menu}
            >
                {state.config.notifications.totalCount > 0 ? 
                Object.keys(state.config.notifications).map((notifi) => {
                    if (state.config.notifications[notifi].time != null && state.config.notifications[notifi].msg != null) {
                        return (
                            <MenuItem onClick={() => handleClick(popupState)} sx={{color: 'white'}}>
                                {state.config.notifications[notifi].displayTime}: {state.config.notifications[notifi].msg}
                            </MenuItem>
                        )
                    } 
                })
                : 
                <MenuItem onClick={() => handleClick(popupState)} sx={{color: 'white'}}>
                    All is good! There are no new notifications.
                </MenuItem>
                }
            </Menu> }
            </React.Fragment>
        )}
        </PopupState>
        </div>
    );
};
export default NotificationsBell;