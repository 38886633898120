
function createDisplayName(tag){
    const tagParts = tag.split('_')
    if(tagParts.length == 1){
        if (tagParts[0] == 'PV'){
            return 'Plastic Viscosity'
        }
            return 'Yield Point'
    }
    else if (tagParts[tagParts.length -1] == 'PPG'){
        if (tagParts[tagParts.length -3] == 'vs'){
            return 'Density Vertical ' + 'PPG'
        }
            return 'Density Coriolis ' + 'PPG'
    }else if(tagParts[tagParts.length -1] == 'm'){
        if(tagParts[1] == 'f'){
            return 'Temperature (F)'
        }
        if(tagParts[0] != 'rho'){
            return 'Temperature (C)'
        }
            return 'Density Coriolis'
    }else{
        if(tagParts[1]=='vs'){
            return 'Density Vertical'
        }if(tagParts[1]=='api'){
            if(tagParts[0] == 'my'){
                if(tagParts[2] == 'pv'){
                    return 'Plastic Viscosity api'
                }
                    return 'Yield Point api'
            }
            return tagParts[2].slice(0,-3)+' RPM api'
        }if(tagParts[1]=='api150'){
            if(tagParts[0] == 'my'){
                if(tagParts[2] == 'pv'){
                    return 'Plastic Viscosity api 150'
                }
                    return 'Yield Point api 150'
            }
            return tagParts[2].slice(0,-3) +' RPM api 150'
        }
        else{
            return tagParts[2].slice(0,-3) +' RPM'
        }
    }
}

export function BuildViewConfig(rawView,RheoMaxView){
const name = rawView[0]
const view = rawView[1]    
const viewConfig = {name:name, charts:[], rheoMax:[], boundaries:'disabled'}
    for (let chart in view){
        const group = {name:chart, categories: [], enabled: true}
        view[chart].forEach(tag => {
            const category = {
                name: createDisplayName(tag),
                datakey: tag,
                enabled: true, 
                current: NaN, 
                isTargetEnabled: false, 
                targets:{
                    isDoubleBoundary: false,
                    min: NaN,
                    max: NaN,
                    target: NaN,
                    lowLow: NaN, 
                    highHigh: NaN
                    }
            }
        group.categories.push(category)
    });
    viewConfig.charts.push(group)
}
    viewConfig.rheoMax.push(RheoMaxView)
    viewConfig.boundaries = {single:false,double:false}
    return viewConfig }

export default BuildViewConfig