import './App.css';
import NewHistoricChart from './components/newHistoricChart';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, createContext, useReducer, useCallback, useMemo } from "react";
import { HashRouter as Router, Routes, Route} from "react-router-dom";
import HomePage from './Pages/HomePage';
import Sidebar from './components/Sidebar';
import MapsPage from './Pages/MapsPage';
import TablePage from './Pages/TablePage';
import LiveChart from './components/LiveChart';
import RheoPort from './Pages/RheoPort';
import { useIsAuthenticated } from "@azure/msal-react";
import Welcome from './Pages/Welcome';
import Profile from './Pages/Profile';
import Settings from './Pages/Settings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraphV1 } from "./Helper/Graph";
import SkidSelector from './components/SkidSelector';
import ConfigProvider from './components/ViewConfig/ConfigProvider';
import SkidsProvider from './components/AvailableSkids/SkidsProvider'
import RenderProvider from './components/ViewConfig/RenderProvider';
import Report from './Pages/Report';
import Historic from'./Pages/Historic'


const globalData = { config: {singleBoundary: false, doubleBoundary: false, email: '', sidebar: true, 
notifications: {totalCount: 0, clearedCount: 0, lowlow: {time: null, msg: null, displayTime: null}, low: {time: null, msg: null, displayTime: null}, high: {time: null, msg: null, displayTime: null}, highhigh:{time: null, msg: null, displayTime: null}}}, 
displayConfig:{
  name:null,
  charts:[],
  rheoMax:[]},
data : [
  {group:'Temp & Density', categories:[
      {name:'Temperature (C)', datakey:'t_inlet_m', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      {name:'Density Coriolis', datakey:'rho_e_m', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      {name:'Density Vertical', datakey:'rho_vs_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
    ]},
  {group:'LSR Rheology', categories:[
      {name:'3 RPM', datakey:'a_3rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      {name:'6 RPM', datakey:'a_6rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      
    ]},
  {group:'HSR Rheology', categories:[
      {name:'100 RPM', datakey:'a_100rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}, 
      {name:'200 RPM', datakey:'a_200rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
    ]},
  {group:'HSR Rheology', categories:[
      {name:'300 RPM', datakey:'a_300rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      {name:'600 RPM', datakey:'a_600rpm_e', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
    ]},
  {group:'Viscosity', categories: [
      {name:'Plastic Viscosity', datakey:'PV', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
      {name:'Yield Point', datakey:'YP', isEnabled: true, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
    ]},
  {group:'Gel', categories: [
      {name:'Gel', datakey:'gel', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
      
    ]},
  {group:'RheoMax', categories: [
    {name:'pH', datakey:'ph', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}, 
    {name:'Water Oil Ratio', datakey:'wor', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}, 
    {name:'Displacement', datakey:'Displacement', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
    {name:'Conductivity WBM', datakey:'CWBM', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}},
    {name:'Conductivity OBM', datakey:'COBM', isEnabled: false, current: NaN, isTargetEnabled: false, targets:{isDoubleBoundary: false, min:NaN,max:NaN,target:NaN, lowLow: NaN, highHigh: NaN}}
  ]},
    
  ]};

export const globalStateContext = createContext(globalData);
export const dispatchStateContext = createContext(null);

const GlobalSateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, newValue) => ({...newValue}),
    globalData
  );
  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>
        {children}
      </dispatchStateContext.Provider>
    </globalStateContext.Provider>
  );
};

const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext)
];

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [state, dispatch] = useGlobalState();
  const [error, setError] = useState();
  const [notifiCount, setNotifiCount] = useState();
  const [sidebar, setSideBar] = useState(true);
  const baseUrl = 'https://rheo-api.azurewebsites.net';
  const configURL = 'https://rheoport-api.azurewebsites.net'
  const emailUrl = 'https://rheoport-api.azurewebsites.net';
  
  const sidebarToggle = (value) => {
    setSideBar(value);
  };

  const notifyWarn = (message, id) => toast.warn(message, {
    toastId: id,
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const notifyError = (message, id) => toast.error(message, {
    toastId: id,
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const sendNotificationEmail = async(msg, email) => {
    console.log('sending email');
    fetch(emailUrl + `/notification?msg=${encodeURIComponent(msg)}&email=${encodeURIComponent(email)}`,{mehtod: "GET",})
    .then(res => res.json())
    .then((item) => {
    })
    .catch(error => {
      setError('An unexpected error occurred, please try again later.');
    })
};
  const getUserData = useMemo(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  instance.acquireTokenSilent(request).then((response) => {
      setToken(response.accessToken)
      callMsGraphV1(response.accessToken).then(response => {
        setGraphData(response);
        state.config.email = response.userPrincipalName;
      });
  }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
          callMsGraphV1(response.accessToken).then(response => setGraphData(response));
      });
  });
  }, []);
  getUserData

  useEffect(() => {
    setInterval(() => {
      let count = 0;
      state.data.forEach((group) => {
        group.categories.forEach((category) => {
          if (!isNaN(category.current) && category.isTargetEnabled) {
            if (category.current < category.targets.lowLow) {
              if (state.config.notifications.lowlow.time===null) {
                console.log("lower boundary exceeded.");
                let msg = `${category.name} has exceeded the lower double boundary! Please attend to this issue immediately.`;
                notifyError(msg, 'lowlow');
                if (state.config.email != '') {
                  sendNotificationEmail(msg, state.config.email);
                }
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.lowlow.time = newDate;
                state.config.notifications.lowlow.displayTime = displayDate;
                state.config.notifications.lowlow.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
              else if ((new Date() - state.config.notifications.lowlow.time) > (15*60*1000)) {  //check if more than 15 minutes have passed since last alert!
                let msg = `${category.name} has exceeded the lower double boundary! Please attend to this issue immediately.`;
                notifyError(msg, 'lowlow');
                if (state.config.email != '') {
                  sendNotificationEmail(msg, state.config.email);
                }
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.lowlow.time = newDate;
                state.config.notifications.lowlow.displayTime = displayDate;
                state.config.notifications.lowlow.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
              }
            if (category.current > category.targets.highHigh) {
              count++;
              if (state.config.notifications.highhigh.time===null) {
                let msg = `${category.name} has exceeded the higher double boundary! Please attend to this issue immediately.`;
                notifyError(msg, 'highhigh');
                if (state.config.email != '') {
                  sendNotificationEmail(msg, state.config.email);
                }
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.highhigh.time = newDate;
                state.config.notifications.highhigh.displayTime = displayDate;
                state.config.notifications.highhigh.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
              else if ((new Date() - state.config.notifications.highhigh.time) > (15*60*1000)) {  //check if more than 15 minutes have passed since last alert!
                let msg = `${category.name} has exceeded the higher double boundary! Please attend to this issue immediately.`;
                notifyError(msg, 'highhigh');
                if (state.config.email != '') {
                  sendNotificationEmail(msg, state.config.email);
                }
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.highhigh.time = newDate;
                state.config.notifications.highhigh.displayTime = displayDate;
                state.config.notifications.highhigh.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
            }
            if (category.current > category.targets.max) {
              count++;
              if (state.config.notifications.high.time===null) {
                let msg = `${category.name} has exceeded the higher single boundary! Please attend to this issue immediately.`;
                notifyWarn(msg, 'high');
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.high.time = newDate;
                state.config.notifications.high.displayTime = displayDate;
                state.config.notifications.high.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
              else if ((new Date() - state.config.notifications.high.time) > (1*60*1000)) {  //check if more than 15 minutes have passed since last alert!
                let msg = `${category.name} has exceeded the higher single boundary! Please attend to this issue immediately.`;
                notifyWarn(msg, 'high');
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.high.time = newDate;
                state.config.notifications.high.displayTime = displayDate;
                state.config.notifications.high.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
            }
            if (category.current < category.targets.min) {
              count++;
              if (state.config.notifications.low.time===null) {
                let msg = `${category.name} has exceeded the lower single boundary! Please attend to this issue immediately.`;
                notifyWarn(msg, 'low');
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.low.time = newDate;
                state.config.notifications.low.displayTime = displayDate;
                state.config.notifications.low.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
              else if ((new Date() - state.config.notifications.low.time) > (1*60*1000)) {  //check if more than 15 minutes have passed since last alert!
                let msg = `${category.name} has exceeded the lower single boundary! Please attend to this issue immediately.`;
                notifyWarn(msg, 'low');
                let newDate = new Date();
                let displayDate = newDate.toLocaleString();
                state.config.notifications.low.time = newDate;
                state.config.notifications.low.time = displayDate;
                state.config.notifications.low.msg = msg;
                state.config.notifications.totalCount++;
                state.config.notifications.clearedCount++;
              }
            }
            
          }
        })
      })
      setNotifiCount(state.config.notifications.clearedCount);
      if (state.config.notifications.clearedCount > 0) {
        document.title = `⚠️ (${state.config.notifications.clearedCount}) RheoView`;
      }else {
        document.title = "RheoView";
      }
    }, 5000);
    return () => {
      clearInterval(5000);
    }
  }, [state.config.singleBoundary])
  //useEffect for fetching availableskids for skidsProvider
  


  return (
  
  <Router>
      <div className="App" style={{background: '#273746', height:'100vh', width: '100vw', overflow: 'auto', 
      backgroundImage: isAuthenticated ? null : "url(https://static.wixstatic.com/media/c837a6_2119733e838e4a2f8813ebde736f99d5~mv2.jpg/v1/fill/w_3840,h_1095,al_b,q_90,enc_auto/c837a6_2119733e838e4a2f8813ebde736f99d5~mv2.jpg)", 
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',}}>
          <RenderProvider key={'RenderProvider'}>
          <SkidsProvider key={'SkidsProvider'}>
          <ConfigProvider key={'ConfigProvider'}>
          <GlobalSateProvider key={'stateprovider'}>
          <div className='Content' style={{float: sidebar ? 'right' : null}}>
          <ToastContainer
            position="bottom-right"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
            />
          <Sidebar key={'sidebar'} isLoggedIn={isAuthenticated} sidebarToggle={sidebarToggle} notifiCount={notifiCount}/>
              {isAuthenticated ? (
              <Routes>
                  <Route key={'settings'} path="/Settings" element={<Settings key={'settings'} />} />
                  <Route key={'profile'} path="/Profile" element={<Profile key={'profile'} />} />
                  <Route key={'home'} exact path="/" element={<HomePage key={'home'} />}></Route>
                  <Route key={'live'} exact path="/Live" element={<LiveChart key={'live'} />}></Route> 
                  <Route key={'historic'} exact path="/Historic" element={<Historic key={'historic'}/>}></Route>
                  <Route key={'map'} exact path="/Map" element={<MapsPage key={'map'} />}></Route>
                  <Route key={'table'} exact path="/Table" element={<TablePage key={'table'} />}></Route>  
                  <Route key={'rheoport'} exact path="/RheoPort" element={<Report key={'rheroport'}/>}></Route>
                  <Route key={'skidSelector'} exact path="/Select" element={<SkidSelector key={'skidSelector'} />}></Route>
                  <Route key={'development'} exact path="/Development" element={<Historic key={'development'}/>}></Route>
                  <Route key={'development rheoport'} exact path="/RheoportDev" element={<Report key={'development rheoport'}/>}></Route>
              </Routes>
              )
              : (
                <Welcome />
              )
              }
              </div> 
            </GlobalSateProvider> 
            </ConfigProvider>
            </SkidsProvider>
            </RenderProvider>
      </div>
    </Router>

  );
}

export default App;
