import '../App.css';
import Charts from'../components/Charts';
import React, { useEffect, useState, useRef} from "react";
import { Paper, Typography, CircularProgress, Box, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import {StatsObject} from '../Helper/StatsObject';
import {DefineYAxis} from '../Helper/DefineYAxis';
import DisplacementChart from './DisplacementChart.js';
import {client, w3cwebsocket as W3CWebSocket } from "websocket";
import { globalStateContext, dispatchStateContext } from '../App.js';
import TargetForm from './TargetForm.js';
import { BootstrapInput, MyIcon, useStyles } from './TargetForm.js';
import { useMediaPredicate } from "react-media-hook";
import GroupHasData from '../Helper/GroupHasData';
import pruneCache from '../Helper/BufferPrune';
import {updateStats} from '../Helper/UpdatedStats';
import {getToken} from '../Helper/GetToken';
import numActiveGroups from '../Helper/numActiveGroups';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import { useMsal } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import setCurrent from "../Helper/setCurrent";
import { useLocation, useNavigate } from 'react-router-dom';
import SelectForm from './ViewConfig/SelectForm';
import BuildViewConfig from '../Helper/BuildViewConfig';
import buildQuery from '../Helper/BuildQuery';
import {useConfig, useConfigDispatch} from './ViewConfig/ConfigProvider'
import { isChartActive } from '../Helper/Chart/chartIsActive';
import { determineYaxisOrientation, isAxisHidden, numActiveCharts } from '../Helper/Chart/yAxisHelpers';
import { configBuilder } from './ViewConfig/ConfigBuilder';



const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext)
];

const LiveChart = (props) =>{
  const locationState = useLocation().state;
  const navigate = useNavigate();
  const [instanceToken, setInstanceToken] = useState(null);
    const [token, setToken] = useState(null);
    const { instance, accounts, inProgress } = useMsal();
    const [time, setTime] = useState(1800);
    const [stats, setStats] = useState(null);
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [liveitems, setLiveItems] = useState([]);
    const [state, dispatch] = useGlobalState();
    const [incoming, setIncoming] = useState([]);
    const [yAxis, setYAxis] = useState([]);
    const [displayDate, setDisplayDate] = useState(null);
    const classes = useStyles();
    const [temporaryStats, setTemporaryStats] = useState(null);
    const [updatedStats, setUpdatedStats] = useState(null);
    const [displacementItems, setDisplacementItems] = useState(null);
    const [availableSkids, setAvailableSkids] = useState(null);
    const [selectedSkid, setSelectedSkid] = useState(null);
    const [isSkidSelected, setIsSkidSelected] = useState(false);
    const [skidOptions, setSkidOptions] = useState(null);
    const [skidViews, setSkidViews] = useState(null);
    const scrollRef = useRef(null);
    const { myKey } = props;
    const trigger = state.data[6].categories[2].isEnabled;
    const moreThan720 = useMediaPredicate("(min-width: 720px)");
    const baseUrl = 'https://rheo-api.azurewebsites.net/';
    const [switching, setSwitching]=useState(false)
    const wsRef = useRef();
    const viewConfig = useConfig();
    const viewConfigDispatch = useConfigDispatch();
    const [numActive,setnumActive] = useState(null)
    const TimeOptions = [300, 600, 1800, 3600]
    const TimeOptionDisplay = (option)=>{
      return (option/60 + ' minutes')
    } 
    const fetchData = async (delta) => {
      const request = {
        scopes: protectedResources.whoami.scopes,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        console.log('i has config?:')
        console.log(state.displayConfig.charts)
        const headers = new Headers();
        const bearer = response.accessToken;
        const end = new Date().toISOString();
        headers.append("Authorization", bearer);
        const options = {
          method: "GET",
          headers: headers
        };
        const start = new Date(new Date().getTime() - (1000 * delta)).toISOString();
        const url = new URL("https://rheoport-api.azurewebsites.net/explorer");
        
        
          url.search = buildQuery( 
            state.displayConfig.charts,
            start,
            end,
            selectedSkid)
            fetch(url, options)
            .then(res => res.json())
            .then((items) =>{
              setLiveItems(items);
              var temp_date = null
              if(!items[items.length-1]){
                temp_date = new Date()
              }else{
                var temp_date = new Date(items[items.length-1].timestamp);
              }
              setDisplayDate(temp_date.toLocaleTimeString());
        })
        .catch(error => {
          console.log(error);
        });   
    })
  };

  const fetchDefaultView = async() => {
    const request = {
      scopes: protectedResources.whoami.scopes,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response) => {
      const headers = new Headers();
      const bearer = response.accessToken;
      const end = new Date().toISOString();
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };

      const url = new URL("https://rheoport-api.azurewebsites.net/GetDefaultSkidView");
      url.search = new URLSearchParams({
          Skid: selectedSkid
        });
      fetch(url, options)
      .then(res => res.json())
      .then((items) =>{
        console.log(items);
        const rheoMax = state.data[state.data.length -1]
        const displayConfig = BuildViewConfig(items,rheoMax)
        state.displayConfig = displayConfig 
        const newConfig = configBuilder(items)
        viewConfigDispatch({
          type:'updateConfig',
          chart: null,
          category: null,
          config: newConfig,
        })

        ;
  })
  .catch(error => {
    console.log(error);
  });
  });
  };

  const fetchSkidViews = async () =>{
    const request = {
      scopes: protectedResources.whoami.scopes,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response) => {
      const headers = new Headers();
      const bearer = response.accessToken;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };
      const url = new URL("https://rheoport-api.azurewebsites.net/GetSkidViews")
      url.search = new URLSearchParams({
        Skid: selectedSkid
      });
      fetch(url,options)
      .then( res => res.json() )
      .then((items) =>{
        setSkidViews(items.Views)
      })
      .catch(error => {
        setError(error);
      });
    });
  };

  const fetchViewConfig = async (view) =>{
    const request = {
      scopes: protectedResources.whoami.scopes,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response) => {
      const headers = new Headers();
      const bearer = response.accessToken;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };
      const url = new URL("https://rheoport-api.azurewebsites.net/SwitchView")
      url.search = new URLSearchParams({
        View: view,
        Skid: selectedSkid
      });
      fetch(url,options)
      .then( res => res.json() )
      .then((items) =>{
        console.log(items);
        const rheoMax = state.data[state.data.length -1]
        const displayConfig = BuildViewConfig(items,rheoMax)
        state.displayConfig = displayConfig 
        const newConfig = configBuilder(items)
        viewConfigDispatch({
          type:'updateConfig',
          chart: null,
          category: null,
          config: newConfig,
        })
      })
      .catch(error => {
        setError(error);
      });
    });
  };

  const fetchAvailableSkids = async () => {
    const request = {
      scopes: protectedResources.whoami.scopes,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response) => {
      const headers = new Headers();
      const bearer = response.accessToken;
      const end = new Date().toISOString();
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };
      
      const url = new URL("https://rheoport-api.azurewebsites.net/GetAvailableSkids");
      fetch(url, options)
      .then(res => res.json())
      .then((items) =>{
        setAvailableSkids(items.skids);
  })
  .catch(error => {
    setError('No skids available skids found for this user.');
  });
  })
};


//Checks if a skid has been passed as an argument, if not it redirects to the select page, only runs on first load
useEffect( ()=>{
  if (locationState==null){
    navigate('/Select',{state:{origin:'/Live'}})
  }
  if(!switching){
    setSelectedSkid(locationState?.targetSkid)  
  }
},[])

//Gets the AD token used to authenticate user to backend, runs when a new skid has been selected
useEffect(()=>{
    async function getInstanceToken(){
      const request = {
        scopes: protectedResources.whoami.scopes,
        account: accounts[0],
      };
    const response = await instance.acquireTokenSilent(request) 
    setInstanceToken(response.accessToken)
    }
    if(!switching){
      getInstanceToken()
    }   
   },[selectedSkid])


   // gets the websocket pass token, runs when instance token is changed, this should only happen on first load after a skid is selected
   useEffect(()=>{
    //function to get the token and store it as state
    async function getToken(skid){
     const url = new URL("https://rheo-api.azurewebsites.net/token")
    //const url = new URL("http://localhost:8000/token");
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + instanceToken); 
    const options = {
        method: "GET",
        headers: headers
      };
      url.search = new URLSearchParams({
       skid: skid
     });
     fetch(url, options).then(res=>res.json()).then((body)=>{      
    setToken(body.token)
     })
    }
    //if an ad token exists it uses it to get the ws token
    if(instanceToken){
      getToken(selectedSkid)
      fetchDefaultView(selectedSkid)
      fetchSkidViews(selectedSkid)

    }   
   },[instanceToken])

   //same as above, but is only ran when selected skid is switching
   useEffect(()=>{
    if(switching){
      async function getToken(skid){
       const url = new URL("https://rheo-api.azurewebsites.net/token")
      //const url = new URL("http://localhost:8000/token");
      const headers = new Headers();
      headers.append("Authorization", "Bearer " + instanceToken);
      
      const options = {
          method: "GET",
          headers: headers
        };
        url.search = new URLSearchParams({
         skid: skid
       });
       fetch(url, options).then(res=>res.json()).then((body)=>{    
        setToken(body.token)
       })     
      }

      getToken(selectedSkid)
      fetchDefaultView(selectedSkid) 
      fetchSkidViews(selectedSkid)
    }
   },[selectedSkid])

useEffect(()=>{
  if(token){
    fetchAvailableSkids()
    fetchData(time)
    const client = new W3CWebSocket('wss://rheo-api.azurewebsites.net', token);
      client.onopen = () => { console.log('websocket client connected')};
      client.onmessage = (message) => {
        setIsLoaded(true);
        let data = JSON.parse(message.data);
        setIncoming(data);
        setCurrent(data, state);
      }
      client.onclose = (close) =>{
        wsRef.current.send(close)
        console.log("closing client")
      }
      
      wsRef.current = client;
        
  }
return () =>{
    if(!wsRef.current){
      console.log("no websocket")
    }else{
      console.log(wsRef.current)
    
      wsRef.current.close()
    }
  }
},[token])

useEffect(() =>{
    let prunedItems = pruneCache(liveitems,time);
    setLiveItems(liveitems => [...prunedItems, ...incoming]);
    if(incoming.length > 0){
      const temp_date = new Date(incoming[incoming.length-1].timestamp);
      setDisplayDate(temp_date.toLocaleTimeString());
    }
}, [incoming]); 

useEffect(()=>{
  if(liveitems.length>0){
    let tempitems = [...liveitems];
    let tempDisplacement = pruneCache(tempitems, 600);
    setDisplacementItems(tempDisplacement);
    let tempStats = new StatsObject(liveitems)
    tempStats.calculateStats()
    if (stats !== {}) {
      setTemporaryStats(stats);
    }
    setStats(tempStats)
    let active = numActiveCharts(viewConfig,tempStats)
    setnumActive(active)
    let newYAxis = DefineYAxis(liveitems,time,false,yAxis)
    setYAxis(newYAxis)
  }else{
  }
    
},[liveitems])

useEffect(()=>{
  if(stats === null){
    console.log('waiting for stats');
  }else{
    setUpdatedStats(updateStats(stats,temporaryStats));
    setIsLoaded(true)
    setError('');
  }
},[stats])


useEffect(()=>{
  if (scrollRef.current && trigger) {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [trigger]);
// Functions Used to handle select in select form
const handleTimeSelect = (event) => {
  let oldTime = time;
  setTime(event.target.value);
  if(event.target.value>oldTime){
    
    fetchData(event.target.value);
  }else{
    let items = liveitems
   let pruneditems = pruneCache(items,event.target.value)
  setYAxis(DefineYAxis(pruneditems,event.target.value,false,yAxis))
  setLiveItems(pruneditems)
  }
  };
const handleSkidSelect = (event) => {
  setIsLoaded(false)
  setSwitching(true)
  setLiveItems([])
  wsRef.current.close()
  setSelectedSkid(event.target.value)
 
  };
const handleViewSelect = (event) =>{
  console.log(`Selected view ${event.target.value}`)
  fetchViewConfig(event.target.value)
}
if(error !== '') {
  return (
    <>
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Typography variant='h5' component="h5" color="error.main" >{error}</Typography>
      </Box>
    </>
  )
}
if(!isLoaded) {
  return(   
    <>
      <div className='Charts-Container'>        
            <CircularProgress color='primary' size={50}/>       
      </div>
    </>            
    )
}else{
return(
    
<React.Fragment key={myKey}> 
<Box name='viewBox' sx={{ display:'inline-block', rowGap:1}}>

  <Paper name= 'ChartsPaper'elevation={10}  sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', columnGap:1, justifyContent:'center', pb:'30px', mt: '30px', backgroundColor:'#273746'}} >
  <Box className='interactiveContainer' sx={{width:'100%' , 
   display: 'flex',  
  flexDirection:{ xs:'column',md:'row'}, justifyContent:{xs:'space-between',md:'flex-start'}, alignItems: {xs:'auto',md:'flex-start'}, p: '10px'}}>
    {availableSkids &&
      <SelectForm title={'Select Skid'} 
        selected={selectedSkid} 
        options={availableSkids} 
        changeHandler={handleSkidSelect}/>
    }
    <SelectForm title={'Select Time Window'} 
      selected={time} 
      options={TimeOptions} 
      changeHandler={handleTimeSelect}
      optionDisplay={TimeOptionDisplay}/>
    {skidViews &&
      <SelectForm title ={'Select View'} 
      selected={viewConfig.name} 
      options={skidViews} 
      changeHandler={handleViewSelect}/>
      } 
    <Box sx={{px: {xs:'40px', md: '0px'}}}>
      {viewConfig.boundaries == 'single' &&
        <TargetForm key={'targetform'} singleBoundary={true}/>
       
      }
      {viewConfig.boundaries == 'double' &&
        <TargetForm key={'targetform double'} singleBoundary={false} />
      }
    </Box>
  </Box>
</Paper>
<Paper name="main-content-screen" elevation={10} sx={{display: 'flex', flexDirection: 'column', columnGap: 1, backgroundColor:'#273746', mt:'30px'}}>
<Typography variant="h6" component="h2" color="white" sx={{fontSize: {xs: '1em', md: '1em'}, mb:2, mt:1, ml:2, display:'flex', justifyContent:'left'}}><ArrowLeftOutlinedIcon fontSize='medium'/> Last Reading: {displayDate}</Typography>
  <Paper name= 'ChartsPaper' sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', columnGap:1, justifyContent:'center', pb:'30px', backgroundColor:'#273746'}} >
  { 

Array.from(viewConfig.charts.values()).
map((chart,index)=>{
  //console.log(chart,viewConfig.charts)
  
  if(chart){
    if(isChartActive(chart,stats)){
      //console.log(`Chart ${chart.name} is active with index ${index}`)
      let hideYAxis = isAxisHidden(index,numActive)
      let yAxisOrientation = determineYaxisOrientation(index,numActive)
      //console.log(`yaxis is active :${hideYAxis}, with orientation: ${yAxisOrientation}`)
      return(
      <Charts 
        key={chart.name} 
        items = {liveitems} 
        isLoaded = {isLoaded} 
        error = {error} 
        //categories = {Array.from(chart.categories.values())} 
        chart = {chart.name}
        colours ={['#83EEFF','yellow',' #FF00FF ', '#ff9800','#1cec12', ' #03a9f4']} 
        groupName={chart.name} 
        groupIndex={index} 
        yAxis={yAxis} 
        stats = {stats} 
        isMoreThan720={moreThan720} 
        tempStats ={temporaryStats} 
        hideYaxis = {hideYAxis} 
        yAxisOrientation = {yAxisOrientation} 
        isHistoric = {false} 
        delta={time} 
        />)
    }
  }
  
}

)
}
  </Paper>
  <Paper>
  
  </Paper>
  </Paper>
  {
  trigger &&
    <Paper name= 'ChartsPaper'elevation={10}  sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', columnGap:1, justifyContent:'center', mt:'30px', backgroundColor:'#273746'}} 
    ref={scrollRef}>
      <DisplacementChart items={displacementItems}/>
    </Paper>
  }   
  </Box>         
</React.Fragment>)
}
}

export default LiveChart;