import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { Chart } from "../../components/ViewConfig/ConfigProvider";
export interface FetchAction{
    type:'GetUserSkids'|'GetSkidData'
    params:params
    url:string
}
type params = Record<string, string>|string[][]|URLSearchParams
export interface TokenRequest{
    scopes:string[],
    account:AccountInfo
}
export interface DataParams{
    timeDelta:number,
    skid:string
}
export interface UserSkidsParams{
    email:string
}
 
interface Options{
    method:"GET",
    headers:Headers
}
const baseConfigURL ="https://rheoport-api.azurewebsites.net"
const devConfigUrl = "http://localhost:5000"
function buildOptions(token:string):Options{
    const headers = new Headers();
    headers.append("Authorization",token)
    console.log(token)
    const options:Options = {
        method:"GET",
        headers: headers
    }
    return options

}
function buildParams(params:params):URLSearchParams{
    const searchParams = new URLSearchParams(
        params
    )
    return searchParams
}



export async function authenticatedFetch(request:TokenRequest,instance:IPublicClientApplication,params:params, urlString:string):Promise<Response>{
     return instance.acquireTokenSilent(request).then((response)=>{
        const options = buildOptions(response.accessToken)
        const url = new URL(urlString)
        url.search = buildParams(params).toString()
         return fetch(url, options)
    })
}
export async function fetchViews(request:TokenRequest,instance:IPublicClientApplication, skid:string):Promise<Response>{
    const params = {
        'Skid':skid
    }
    const url = baseConfigURL+'/GetSkidViews'
    return authenticatedFetch(request, instance, params, url)
}
export async function fetchDeafultView(request:TokenRequest,instance:IPublicClientApplication, skid:string):Promise<Response>{
    const params = {
        'Skid':skid
    }
    const url = baseConfigURL+'/GetDefaultSkidView'
    return authenticatedFetch(request, instance, params, url)
}
export async function fetchViewConfig(request:TokenRequest,instance:IPublicClientApplication, skid:string, view:string):Promise<Response>{
    const params = {
        'Skid':skid,
        'View':view
    }
    const url = baseConfigURL+'/SwitchView'
    return authenticatedFetch(request, instance, params, url)
}
export async function fetchAvailableSkids(request:TokenRequest,instance:IPublicClientApplication):Promise<Response>{
    const params = {}
    const url = baseConfigURL+'/GetAvailableSkids'
    return authenticatedFetch(request, instance, params, url)
}

export interface DataOptions{
    start: number,
    end: number,
    skid: string,
    view: Map<string, Chart>,
    aggregation: number
}
function buildDataParams(options:DataOptions):URLSearchParams{
 const searchParams = new URLSearchParams(
    {
        'Skid': options.skid,
        'Aggr': `${options.aggregation}`,
        'Start': new Date(options.start).toISOString().slice(0, -1),
        'End': new Date(options.end).toISOString().slice(0, -1),
    }
 )
    options.view.forEach(chart =>{
        chart.categories.forEach(category => {
            searchParams.append('Tags', category.datakey)
        });
    })
 return searchParams
}
export async function fetchData(request:TokenRequest,instance:IPublicClientApplication, options:DataOptions):Promise<Response>{
    const params = buildDataParams(options) 
    const url = baseConfigURL+'/GetData'
    return authenticatedFetch(request, instance, params, url)
}
export interface ReportOptions{
    start: number,
    end: number,
    skid: string,
    resample_rate: number,
    emailStatus: boolean,
    emails:Set<string>,
    length:number
    
}
function buildReportParams(options:ReportOptions):URLSearchParams{
    const searchParams = new URLSearchParams({
        'start': new Date(options.start).toISOString(),
        'end': new Date(options.end).toISOString(),
        'emailStatus':`${options.emailStatus}`,
        'resample_rate':`${options.resample_rate}`,
        'skid':options.skid,
        'length':`${options.length}`
    })
    options.emails.forEach(email=>{
        searchParams.append('emails',email)
    })
    return searchParams
}
export async function fetchReport(request:TokenRequest,instance:IPublicClientApplication, options:ReportOptions){
    const params = buildReportParams(options)
    const url = baseConfigURL+'/SendReport'
    return authenticatedFetch(request, instance, params, url)
}