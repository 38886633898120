import React, {useState} from 'react'
//mui imports
import { Box, Button } from '@mui/material';

//internal imports
import { useSkidConfig, useSkidConfigDispatch } from '../components/AvailableSkids/SkidsProvider'
import ReportSelector from '../components/Report/ReportSelector';
import ReportPresenter from '../components/Report/ReportPresenter';

//component
function Report(){
    const Skids = useSkidConfig()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState<ArrayBuffer>(null)
    const [message,setMessage] = useState('Select Skid to get Started')

    function handleFetchResp(resp:Promise<Response>){
        resp.then(res => {
            if (res.status >= 400){
                throw new Error("Server Encoutered a problem while generating PDF")
            }
            return res.blob()
        }).then((blob)=>{
            if(blob){
                blob.arrayBuffer().then(buffer => {
                    setData(buffer)
                    setLoading(false)
                })
            }
        }).catch(error =>{
            setError(error.message);
            setLoading(false)
        })
    }
    return(
        <Box className='View-Box'>
           <ReportSelector skids={Skids} setLoading={setLoading} dataHandler={handleFetchResp} setMessage={setMessage}/>
           <ReportPresenter data={data} error={error} loading={loading} message={message}/>
        </Box>
    )
}

export default Report