//react imports
import React, {useState} from 'react'
import { useMsal } from "@azure/msal-react";
//mui imports
import { Box, Button, Checkbox, Paper, responsiveFontSizes } from '@mui/material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
//internal imports
import { loginRequest, protectedResources } from "../../authConfig"
import SelectForm from '../Select/SelectForm';
import { fetchDeafultView, fetchViewConfig, fetchViews, fetchReport } from '../../Helper/Fetch/fetch';
import {useConfig, useConfigDispatch} from '../ViewConfig/ConfigProvider'
import { configBuilder } from '../ViewConfig/ConfigBuilder';
import DateForm from '../Select/DateForm';
import EmailForm from '../Select/EmailForm';
import { epochSecondsToHours } from '../../Helper/timeHelpers';
import validateReportFetch from './validateReportFetch';


//Type Definitons
interface SelectorProps{
    skids: Set<string>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    dataHandler: ResponeHandler,
    setMessage: React.Dispatch<React.SetStateAction<string>>

   
}
export interface ResponeHandler{
    (resp:Promise<Response>): void
}
export interface HandlerFunc{
    (event:React.ChangeEvent<HTMLInputElement>): void
}
//Helper functions
function getAggregateOptions(start:number, end:number):number[]{
    const delta = end-start
    const day = 8640
    const minute = 60
    const hour = 3600
    if (delta >= day) {
        return [5*minute, 30*minute, hour, 2*hour]
    }
    return [5,30,minute, 5*minute]
}
//Start of component
function ReportSelector(props:SelectorProps){
//constants
    const { skids, setLoading, dataHandler, setMessage } = props
    const { instance, accounts, inProgress } = useMsal();
    const viewConfig = useConfig();
    const viewConfigDispatch =useConfigDispatch();
    const request = {scopes: protectedResources.whoami.scopes,account: accounts[0]}
    const [skidViews, setSkidViews] = useState(['']);
    const [selectedSkid, setSelectedSkid] = useState('');
    const [startTime, setStartTime] = useState(Date.now())
    const [endTime, setEndTime] = useState(Date.now())
    const [aggregation,setAggregation] = useState(60)
    const [mails, setMails] = useState(new Set<string>)
    const [email,setEmail] = useState(false)

    const emailLabels= { inputProps: { 'aria-label': 'email checkbox' }}
    
    const aggeregateOptionText = (option:number)=>{
        if (option>3600){
            const text = `${option/3600} Hours`
            return text
        }else if(option===3600){
            const text = `${option/3600} Hour`
            return text
        }else if (option>60){
            const text = `${option/60} Min`
            return text
        }else{
            const text = `${option} Sec`
            return text
        }
    }
//Handlers 
    function StartSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        console.log("start time:",event.target.value, typeof(event.target.value))
        setStartTime(Date.parse(event.target.value))
        
    }
    function EndSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        console.log("end time:",event.target.value,typeof(event.target.value))
        setEndTime(Date.parse(event.target.value))
    }

    function ConfigSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        fetchViewConfig(request,instance,selectedSkid,event.target.value).then(res => res.json()).then((items) =>{
            viewConfigDispatch({
                type:'updateConfig',
                chart: null,
                category: null,
                config: configBuilder(items),
            })
        })
    }

    function SkidSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        fetchDeafultView(request,instance,event.target.value).then(res => res.json()).then((items) =>{
            viewConfigDispatch({
                type:'updateConfig',
                chart: null,
                category: null,
                config: configBuilder(items),
              })
        })
       fetchViews(request,instance,event.target.value).then(res => res.json()).then((items)=>{
            setSkidViews(items.Views)
       })
       setSelectedSkid(event.target.value)
    }
    function AggregationSelectHandler(event:React.ChangeEvent<HTMLInputElement>){
        setAggregation(parseInt(event.target.value))
    }
    function emailToggleHandler(event: React.ChangeEvent<HTMLInputElement>){
        setEmail(event.target.checked)
    }
    function emailAddHandler(mail:string){
        mails.add(mail)
        setMails(new Set(mails))
    }
    function emailRemoveHandler(event:React.MouseEvent<HTMLElement>){
        const e = event.currentTarget as HTMLInputElement
        console.log(event.currentTarget)
        mails.delete(e.value)
        setMails(new Set(mails))
    }
    function fetchHandler(){
        const length = epochSecondsToHours(endTime-startTime)
        console.log("length:",length)
        const options = {start: startTime, end: endTime, skid: selectedSkid, resample_rate:aggregation, emailStatus:email, emails:mails, length:Math.ceil(length)}
        const msg = validateReportFetch(options)
        if (msg==='ok'){
            setLoading(true)
            let resp = fetchReport(request, instance, options)
            dataHandler(resp)
        }else{
            setMessage(msg)
        }     
    }
    function renderEmailForm(email:boolean){
        if(email){
            return(
            <Box>
                <EmailForm mails={Array.from(mails)} emailAddHandler={emailAddHandler} emailRemoveHandler={emailRemoveHandler} />
            </Box>
            )
           
        }
    }
return(
    <Paper className='Selector-Paper' key='Selector' elevation={8} sx={{backgroundColor:'#273746', width: '100%', py:'15px'}} >
        <Box display="flex" justifyContent="space-around" alignItems="center">
            <SelectForm title='Skid' options={Array.from(skids)} selected={selectedSkid} changeHandler={SkidSelectHandler} />
            <SelectForm title='View' options={skidViews} selected={viewConfig.name} changeHandler={ConfigSelectHandler} />
            <DateForm title='Time' startHandler={StartSelectHandler} endHandler={EndSelectHandler} startValue={startTime} endValue={endTime} />
            <SelectForm title='Aggregation' options={getAggregateOptions(startTime,endTime)} selected = {aggregation} changeHandler={AggregationSelectHandler} optionDisplay={aggeregateOptionText} />
            <Checkbox
                {...emailLabels}
                checked={email}
                onChange={emailToggleHandler}
                icon={<AttachEmailOutlinedIcon fontSize="large" />}
                checkedIcon={<AttachEmailIcon fontSize="large"  />}
            />
        </Box>
        
            {renderEmailForm(email)}
                
        <Button variant="contained" color="success" onClick={fetchHandler}>Get Report</Button>
    </Paper>
    )
}

export default ReportSelector