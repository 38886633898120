//react imports
import React, {useState} from 'react'
//mui imports
import { Box, Button } from '@mui/material';
import HistoricSelector from '../components/Historic/HistoricSelector';
import HistoricPresenter from '../components/Historic/HistoricPresenter';
//external imports

//internal imports
import { useSkidConfig, useSkidConfigDispatch } from '../components/AvailableSkids/SkidsProvider'
//type definitions
export interface DataPoint{
    [index:string]: number;
}
//component
function Historic(){
    const Skids = useSkidConfig()
    const [data, setData] = useState<DataPoint[]>([]) 
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [skid, setSkid] = useState('');
    const [startTime, setStartTime] = useState(Date.now()-8*3600*1000)
    const [endTime, setEndTime] = useState(Date.now())
    const [message,setMessage] = useState('Select Skid to get Started')
    
    function handleFetchResp(resp:Promise<Response>){
        resp.then(res => res.json()).then((items:DataPoint[])=>{
            setData(items)
            setLoading(false)
        }).catch((error)=>{
            setError(error)
        })   
    }
    return(
        <Box className='View-Box'>
           <HistoricSelector 
            skids={Skids} 
            setLoading={setLoading} 
            dataHandler={handleFetchResp} 
            setSkid={setSkid} skid={skid} 
            start={startTime} setStart={setStartTime}
            end={endTime} setEnd={setEndTime}
            setMessage={setMessage} />
           <HistoricPresenter data ={data} error={error} loading = {loading} skid={skid} start={startTime} end={endTime} message={message}/>
        </Box>
        
    )
};

export default Historic