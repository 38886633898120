export  function DefineYAxis(items,timeOption, isHistoric,oldYAxis){
    function createTimeWindowAxis(timeOption,isHistoric, items){
        //Checks if isHistoric, if historic defines a y-axis spanning bacjwards based on timeoption, else defines y axis spanning forward
       if(isHistoric){
        return createHistoricTimewindow(items,timeOption)
       }
       return createOffsetWindow(items, timeOption, oldYAxis)
    }

    function createTargetWindow(timeOption ){
        const now = Date.now();
        const epochWindow = timeOption *1000;
        var start = new Date(now + 7*60*1000)
        if(timeOption <= 10*60){
            start = new Date(now + 3*60*1000)
        }
        const end = new Date(start - epochWindow)
        return [end.getTime(), start.getTime()]

    }
    function createOffsetWindow(items, timeOption, oldYAxis){
        const targetWindow = createTargetWindow(timeOption)
        //console.log(targetWindow)
        if(!oldYAxis[1]){
            //console.log('no old axis, returning target')
            return targetWindow
        }
        //console.log(`targetWindow:${new Date(targetWindow[0])}, ${new Date(targetWindow[1])}`)
        //console.log(`oldYAxis:${new Date(oldYAxis[0])}, ${new Date(oldYAxis[1])}`)
        if(targetWindow[1]-targetWindow[0] != oldYAxis[1]-oldYAxis[0]){
            console.log("time option changed")
            return targetWindow
        }
        const offsetStart = targetWindow[1]-7*60*1000
        const offsetLimit = oldYAxis[1]-2*60*1000
        //console.log(`offsetLimit; raw:${offsetLimit}, hr:${new Date(offsetLimit)}`)
        //console.log(`last item; raw:${items[items.length-1].timestamp}, hr:${new Date(items[items.length-1].timestamp)}`)
        
        if(items[items.length-1].timestamp < offsetLimit){
            //console.log('returning old axis')
            return oldYAxis
        }
        //oldYAxis[0] = new Date(oldYAxis[0]+5*60*1000) 
        //console.log(targetWindow)
        console.log('returning modified window')
        return targetWindow


    }
    function createTimeWindow(items, timeOption){
        let start = 0
        let end = items.length-1
     if(items[end].timestamp < items[start].timestamp){
        start  = items.length-1
        end = 0
     }
    // represent the items as a timewindow of start and ned items in epoch time
        let epochItemsWindow = items[end].timestamp-items[start].timestamp
    // Determines the requested time window in epoch Time
        let epochWindow = timeOption *1000;
    // Adds epochtime to the end off the timewindow if items window is smaller than requested
        if(epochWindow>epochItemsWindow){
            let difference = epochWindow-epochItemsWindow
        if(difference>60*60*100){
        }
        let yAxis =[items[start].timestamp, items[end].timestamp+difference]
        return yAxis
    }
    // 
    let yAxis =[items[start].timestamp,items[end].timestamp]
    return yAxis
    }
    function createHistoricTimewindow(items,timeOption){
        console.log("creating historic time window")
        const now = Date.now()
        
        //0o0 == 00, javascript weirdness.
        const startOfDay = new Date(new Date().setHours(0o0,0o0,0o0))
        var axisStart = new Date(startOfDay - 60*60*24*7*1000).getTime() 
        var axisEnd = new Date(new Date().setHours(23,59,59)).getTime() 
        //console.log(axisStart)
        //console.log(axisEnd)
        console.log(items[items.length-1].timestamp)
        if(items[items.length-1].timestamp<axisStart){
            console.log("creating new axis")
            axisStart = items[0].timestamp
            axisEnd = items[items.length-1].timestamp
            return [axisStart ,axisEnd]
        }
        if(timeOption<168){
            return [now-timeOption*60*60*1000,now]
        } 
        return [axisStart ,axisEnd]
    }

    //Checks if items exists and determines if items are DESC or ASCE
    if(!items.length==0){
        // Creates the Axis
      return createTimeWindowAxis(timeOption,isHistoric, items)
    }else{
        console.log('no liveitems defined')
        return NaN
    }
 }
 export default DefineYAxis;