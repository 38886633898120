import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Box
  } from '@mui/material'
import { keyframes } from '@mui/system';
import { globalStateContext, dispatchStateContext } from '../App.js';
import React from 'react';
const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
  ];

const StatsMUI = (props) => {
    const { stats, categories, updatedStats, liveitems, colours, chartIndex} = props
    const [state, dispatch] = useGlobalState();
    if(!stats){
        return(<></>)
    }
    if(!updatedStats){
        return(<></>)
    }
    const rows = categories.map((category)=>{
        if(!stats[category.datakey]){

            return null
        }
        let target = NaN;
        for (let item of state.data) {
            for (let cat of item.categories) {
                if (cat.datakey === category.datakey) {
                    target = cat.targets.target;
                }
            }
        }
       return {
        name:category.name,
        datakey: category.datakey,
        average:(stats[category.datakey].Sum / stats[category.datakey].Count).toFixed(2),
        min:stats[category.datakey].Min.toFixed(2), 
        max:stats[category.datakey].Max.toFixed(2),
        target:target,
        current: stats[category.datakey].Current.toFixed(2),
    }
    });

    if(!rows[0]){
        return(<>
        </>)
    }
    return(<>
    <TableContainer component={Box} sx={{width:'76%', ml:3}}>
        <Table size='small'>
            <TableHead>
                <TableRow style={{height:33}}>
                    <TableCell align="left" sx={{color: 'white', p:0, m:0}}>Category</TableCell>
                    <TableCell align="center" sx={{color: 'white', p:0, m:0}}>Avg</TableCell>
                    <TableCell align="center" sx={{color: 'white', p:0, m:0}}>Min</TableCell>
                    <TableCell align="center" sx={{color: 'white', p:0, m:0}}>Max</TableCell>
                    <TableCell align="center" sx={{color: 'white', p:0, m:0}}>Current</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
               { rows.map((row,index)=>{
                if(row){
                    if (row.name === "Temperature (C)") {
                        row.name = "Temp C°"
                       };
                       if (row.name === "Specific Gravity") {
                        row.name = "SG"
                       };
                       if (row.name === "Plastic Viscosity") {
                        row.name = "PV"
                       };
                       if (row.name === "Yield Point") {
                        row.name = "YP"
                       };
                       let isGreen = true;
                       if (Math.abs(row.current-row.target) > 2) {
                        isGreen = false;
                       }
                       if (!updatedStats[row.datakey]) {
                        updatedStats[row.datakey] = {
                            average: true,
                            min: true,
                            max: true,
                        };
                       }
                        return( 
                        <TableRow key={row.name} style={{height:33}} >
                            <TableCell align="left" sx={{color: colours[index+chartIndex], p:0, m:0}}>{row.name}</TableCell>
                            <TableCell align="center" sx={{color: 'white', p:0, m:0, animation: updatedStats[row.datakey].average ? `${blink} 1.5s linear` : '0s linear'}}>{row.average} </TableCell>
                            <TableCell align="center" sx={{color: 'white', p:0, m:0, animation: updatedStats[row.datakey].min ? `${blink} 1.5s linear` : '0s linear'}}>{row.min} </TableCell>
                            <TableCell align="center" sx={{color: 'white', p:0, m:0, animation: updatedStats[row.datakey].max ? `${blink} 1.5s linear` : '0s linear'}}>{row.max} </TableCell>
                            {isNaN(row.target) 
                                ?  <TableCell align="center" sx={{color: 'white', p:0, m:0, animation: `${blink} 1.5s linear`}}>{row.current} </TableCell>
                                : <TableCell align="center" sx={{color: isGreen ? 'green' : 'red', p:0, m:0, animation: `${blink} 1.5s linear`}}>{row.current} </TableCell>
                            }
                        </TableRow>)
                }
               
               
                })}
            </TableBody>
        </Table>
    </TableContainer>
    
    </>
    )}

export default StatsMUI

