import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { loginRequest } from "../authConfig";
import { Typography } from "@mui/material";
import { callMsGraphV1 } from "../Helper/Graph";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";

const Profile = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const name = accounts[0] && accounts[0].name;
    const infoMap = {'Operator':'Test Oil', 'Country':'Test Land', 'iMS Contact':'IMS Tester', 'Well Name':'Test Well', 'Field':'Test Field', 'Rig Name':'Test Rig', 'Rig Type':'Land Rig', 'Local Phone':'+12 345 67 890', 'Local Contact':'TO Contact'}
    const [photo, setPhoto] = useState(null);

useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphV1(response.accessToken).then(response => setGraphData(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraphV1(response.accessToken).then(response => setGraphData(response));
            });
        });

    }, []);

    console.log(graphData);
    const org = Object.keys(infoMap).map((key, index) => {
        return (
            <TextField 
            key={index} 
            label={key} 
            value={infoMap[key]}
            variant="standard"
            color="primary"
            focused
            sx={{input: { color: 'white'}, minWidth: 'fit-content'}}
            InputProps={{
                readOnly: true,
                }}
            fullWidth
            />
        )
    });
    const completeOrg = [];
    for (let i = 0; i < org.length-2; i += 3) {
        completeOrg.push(
            <Stack spacing={2} direction='row' sx={{m:2, minWidth: '40%'}}>
                {org[i]}
                {org[i+1]}
                {org[i+2]}
            </Stack>)
        };

    return (
        <div style={{marginTop: '50px', width: '60vw'}}>
            {graphData ?
            <div>
                <Box
                component="form"
                sx={{
                    //'& > :not(style)': { m: 1, width: '60ch' },
                    m: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                noValidate
                autoComplete="off"
                >
                <Typography varaint="subtitle" component="h1" sx={{color: 'white', m:2, fontSize: '2em'}}>User Information</Typography>
                <Stack direction="row" spacing={2} sx={{minWidth: '40%', m:2}} >
                <TextField
                    label="First Name"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.givenName}
                    sx={{input: { color: 'white'}, minWidth: 'fit-content'}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth

                />
                <TextField
                    label="Last Name"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.surname}
                    sx={{input: { color: 'white' }}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth
                />
                </Stack>
                <Stack direction="row" spacing={2} sx={{minWidth: '40%', m:2}}>
                <TextField
                    label="Email"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.userPrincipalName}
                    sx={{input: { color: 'white' }}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth
                />
                <TextField
                    label="Id"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.id}
                    sx={{input: { color: 'white' }}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth
                />
                </Stack>
                <Stack direction="row" spacing={2} sx={{minWidth: '40%', m:2}}>
                <TextField
                    label="Phone"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.mobilePhone}
                    sx={{input: { color: 'white' }}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth
                />
                <TextField
                    label="Job Title"
                    variant="standard"
                    color="primary"
                    focused
                    value={graphData.jobTitle}
                    sx={{input: { color: 'white' }}}
                    InputProps={{
                        readOnly: true,
                      }}
                    fullWidth
                />
                </Stack>
                <Typography varaint="subtitle" component="h1" sx={{color: 'white', m:2, fontSize: '2em'}}>Organization Information</Typography>
                {completeOrg}
            </Box>
            </div>
            : null
            }
        </div>
    );
};
export default Profile;