
import React, { ReactNode,createContext, useReducer, type Dispatch, useContext } from 'react';
import { updateConfig,addCategory, addChart, updateCategory, updateChart, removeCategory, removeChart, toggleCategory, toggleBoundaries } from './ConfigHelpers';
 //Interfaces
interface Props {
  children?: ReactNode
  // any props that come into the component
}
//export Interfaces

export interface Riverbank{
  riverbankEnabled:boolean,
  doubleBankEnabled:boolean,
  targetEnabled:boolean,
  current:number | null,
  target:number | null,
  innerMin:number | null,
  outerMin:number | null,
  innerMax:number | null,
  outerMax:number | null
}
export interface Category{
  name:string,
  datakey:string,
  enabled:boolean,
  riverbank:Riverbank
}
export interface Chart{
  name:string,
  enabled:boolean,
  categories:Map<string,Category>,
}
export interface Boundaries{
  single:boolean,
  double:boolean
}
export interface DisplayConfig{
  name:string,
  charts:Map<string,Chart>,
  rheoMax:Map<string,Chart>,
  boundaries: 'double' | 'single' | 'disabled'
  switch: 1 | 0
}
export interface Action{
  type:'updateConfig' |'addCategory' | 'addChart' | 'updateCategory'| 'updateChart' | 'removeChart' | 'removeCategory' | 'toggleCategory' | 'toggleBoundaries' ,
  chart: Chart | null,
  category: Category | null | string,
  config: DisplayConfig | null,
}
//Interfaces end

export const ConfigContext = createContext<DisplayConfig |null >(null);
const ConfigDispatchContext = createContext<Dispatch<any> | null>(null);


//export functions
export function useConfig(){
  return useContext(ConfigContext)
}
export function useConfigDispatch(){
  return useContext(ConfigDispatchContext)
}

export default function ConfigProvider({ children }:Props) {
    const [config, dispatch] = useReducer(ConfigReducer, initialConfig);
    
    return (
      <ConfigContext.Provider value={config}>
        <ConfigDispatchContext.Provider value={dispatch}>
          {children}
        </ConfigDispatchContext.Provider>
      </ConfigContext.Provider>
    );
  }
  function ConfigReducer(config:DisplayConfig,action:Action){
    switch(action.type){
        case 'updateConfig':{
          return updateConfig(config,action)
        }
        case 'addCategory': {
          return addCategory(config,action)
        }
        case 'addChart':{
          return  addChart(config,action)
        }
        case 'updateCategory':{
          return updateCategory(config,action)
        }
        case 'updateChart':{
          return updateChart(config,action)
        }
        case 'removeChart':{
          return removeChart(config,action)
        }
        case 'removeCategory':{
          return removeCategory(config,action)
        }
        case 'toggleCategory':{
          return toggleCategory(config,action)
        }
        case 'toggleBoundaries':{
          return toggleBoundaries(config, action)
        }
        default: {
            throw Error('Unknown action: ' + action.type);
          }
    }
  }

  const initialConfig:DisplayConfig ={name:'init',charts:new Map<string,Chart>,rheoMax:new Map<string,Chart>, boundaries:'disabled', switch:0}
  