import { Chart, useConfig } from '../ViewConfig/ConfigProvider'
import {FormControlLabel, Switch, Typography} from '@mui/material';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { HandlerFunc } from './SwitchForm';

const { palette } = createTheme();
const theme = createTheme({
    palette: {
      primary: palette.augmentColor({color: {main : '#83EEFF'}})
    },
});
export function categoryLabelMaker(sub_config:Map<string,Chart>, handler:HandlerFunc):React.JSX.Element[]{
    
    const labels = Array.from(sub_config.values()).map((chart)=>{
        
        return Array.from(chart.categories.values()).map((category) =>{
        return(
        <ThemeProvider theme={theme}>
        <FormControlLabel
        value={category.name}
        control={<Switch color='primary' checked={category.enabled} onChange={(event)=>handler(event)} size='small'/>}
        label={<Typography component='p' variant='body1' sx={{color:'white'}}>{category.name}</Typography>}
        labelPlacement='start'
        checked={category.enabled}/>
        </ThemeProvider>   
        )
    })
   
    })
    const result:React.JSX.Element[] = []
    labels.map((categories)=>{
        result.push( ...categories)
    })
    
return result
}
export function boundariesLabelMaker(handler:HandlerFunc,boundaries:'single'|'double'):React.JSX.Element[]{
    const options = ['single', 'double']
    const labels = options.map((option)=>{
        let checked= false
        console.log('checking if checked should be checked')
        if(boundaries==option){
            checked=true
        }
        return(
            <ThemeProvider theme={theme}>
            <FormControlLabel
            value={option}
            control={<Switch color='primary' checked={checked} onChange={(event)=>handler(event)} size='small'/>}
            label={<Typography component='p' variant='body1' sx={{color:'white'}}>{option}</Typography>}
            labelPlacement='start'
            checked={checked}/>
            </ThemeProvider>
        )
    })
    return labels  
}


