const setCurrent = (data, state) => {
    //console.log(state);
    data.forEach((item) => {
        Object.keys(item).map((key) => {
          state.data.forEach((group) => {
            group.categories.forEach((category) => {
              if (category.datakey === key) {
                category.current = item[key];
              }
            })
          })
      })
    });
};
export default setCurrent;