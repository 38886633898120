import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';

const styles = {
    root: {
        height:'600px',
        width: '80%',
        overflowX: 'auto',
    },
};
const markers = [
    {lat: 58.942499, lng: 5.726529},
    {lat: 60.4, lng: 2.667653},
    {lat: 60.51307, lng: 3.46713},
    {lat: 56.17854, lng: 3.46708},
    {lat: 58.9951, lng: 5.6755},
    {lat: 60.2974, lng: 5.2864},
    {lat: 60.2974, lng: 5.2864},
    
]
const Google = () => {
    return (
        <Paper elevation={3} style={styles.root}>
        <LoadScript
        googleMapsApiKey='AIzaSyBMtO8PA0wJqTQTOWfKtSLW8Fu3OhANhno'
        >
            <GoogleMap
            key="google"
            mapContainerStyle={{width: '100%', height:'100%'}}
            center={{lat: 58, lng: 4}}
            zoom={5}
            defaultOptions={{
                styles: [{ stylers: [{ 'saturation': 50 }, { 'gamma': 0.5 }] }]
            }}
            >
            {markers.map((location) => (
                <Marker position={location}/>
            ))}
            </GoogleMap>
        </LoadScript>
        </Paper>
    );
};

export default Google;