import { Action, DisplayConfig, Chart, Category, Riverbank } from "./ConfigProvider";
import cloneDeep from 'lodash.clonedeep'

function flipSwitch(input:1|0):1|0{
    
    if(input==1){
        console.log(0)
        return 0
    }
    console.log(1)
    return 1
}
export function addCategory(config:DisplayConfig,action:Action){
    if(action.category && action.chart){
        const target = action.category as Category
        config.charts.get(action.chart.name)?.categories.set(target.name,target)
        const newConfig = {...config}
        return newConfig
    }
    return config
}

export function addChart(config:DisplayConfig,action:Action){
    if(action.chart){
        config.charts.set(action.chart.name,action.chart)
        const newConfig = {...config}
        return newConfig
    }
    return config
}
export function updateCategory(config:DisplayConfig,action:Action){
    if(action.category as Category && action.chart){
        const target = action.category as Category
        config.charts.get(action.chart.name)?.categories.set(target.name,target)
        const newConfig = {...config}
        return newConfig
    }
    return config
}
export function updateChart(config:DisplayConfig,action:Action){
    if(action.chart){
        config.charts.set(action.chart.name,action.chart)
        const newConfig = {...config}
        return newConfig
    }
    return config
}
export function removeCategory(config:DisplayConfig,action:Action){
    if(action.category as Category && action.chart){
        const target = action.category as Category
        config.charts.get(action.chart.name)?.categories.delete(target.name )
        const newConfig = {...config}
        return newConfig
    }
    return config
}
export function removeChart(config:DisplayConfig,action:Action){
    if(action.chart){
        config.charts.delete(action.chart.name)
        const newConfig = {...config}
        return newConfig
    }
    return config
}

export function updateConfig( config:DisplayConfig, action:Action){
    if(action.config){
        console.log('returning:',action.config)
    return action.config
    }
    return config
}

export function toggleCategory(config:DisplayConfig, action:Action){
    console.log('triggering category toggle')
    if(action.category as string){
        const tempConfig = {...config}
        tempConfig.charts.forEach((chart, name)=>{
            let tempChart = {...chart}
            tempConfig.charts.set(name,tempChart)
            tempChart.categories.forEach((category,name)=>{
                let tempCategory = {...category}
                if(name == action.category){
                    tempCategory.enabled = !category.enabled
                    tempChart.categories.set(name,tempCategory)
                    const newConfig = {...tempConfig}
                    
                    newConfig.switch = flipSwitch(newConfig.switch)
                   
                    return newConfig
                }
            })
        })
    }
return config}

export function toggleBoundaries(config:DisplayConfig, action:Action){
    if(action.config){
        console.log(action.config)
        const newConfig = {...config}
        newConfig.boundaries = action.config.boundaries
        console.log('returning',newConfig)
        return newConfig
    }
    return config
}

export function setBoundary(config:DisplayConfig,option:'single'|'double'):DisplayConfig{
    const tempConfig = {...config}
    if(config.boundaries==option){
      tempConfig.boundaries ='disabled'
    }else{
      tempConfig.boundaries = option
    }
    return tempConfig
}
interface Targets{
    innerMin:number,
    outerMin:number | null,
    innerMax:number,
    outerMax:number | null
}
export function addValues(config:DisplayConfig,values:Targets,categoryName:string):DisplayConfig{

    config.charts.forEach((chart,chartName)=>{
        console.log(`Checking if ${chart.categories} contains ${categoryName}`)
        if(chart.categories.has(categoryName)){
            const riverbank=config.charts.get(chartName).categories.get(categoryName).riverbank
            riverbank.innerMin = values.innerMin
            riverbank.innerMax = values.innerMax
            riverbank.riverbankEnabled = true
            if (values.outerMax || values.outerMin ){
                riverbank.outerMax = values.outerMax
                riverbank.outerMin = values.outerMin
                riverbank.doubleBankEnabled = true
            }
            console.log(riverbank)
            config.charts.get(chartName).categories.get(categoryName).riverbank=riverbank
        }
    })
    return config
}

export function getEnabledCategories(config:DisplayConfig):Category[]{
    const activeCategories:Category[] = []
    config.charts.forEach((chart,_)=>{
        chart.categories.forEach((category,_)=>{
            if(category.enabled){
                activeCategories.push(category)
            }
        })
    })
    console.log('returning',activeCategories)
    return activeCategories
}

export function getEnabledRheoMaxCategories(config:DisplayConfig):Category[]{
    const activeCategories:Category[] = []
    config.rheoMax.forEach((chart,_)=>{
        chart.categories.forEach((category,_)=>{
            if(category.enabled){
                activeCategories.push(category)
            }
        })
    })
    console.log('returning',activeCategories)
    return activeCategories
}

export function getActiveRiverbanks(config:DisplayConfig):Category[]{
    const activeRiverbankCategories:Category[] = []
    config.charts.forEach((chart,_)=>{
        chart.categories.forEach((category,_)=>{
            if(category.riverbank.riverbankEnabled || category.riverbank.doubleBankEnabled){
                activeRiverbankCategories.push(category)
            }
        })
    })
    console.log('returning',activeRiverbankCategories)
    return activeRiverbankCategories
}





