export function buildQuery(charts, start, end, skid){
   const searchParams = new URLSearchParams({
        start:start,
        end:end,
        skid:skid,
    });
    
    charts.forEach(chart =>{
            chart.categories.forEach(category=>{
                searchParams.append('tags',category.datakey)
            })
    })
    return searchParams
}
export default buildQuery;