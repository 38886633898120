
export const msalConfig = {
    auth: {
      clientId: "099dd97c-3ff3-40ee-a86e-0df6ca26068b",
      authority: "https://login.microsoftonline.com/65336455-2581-46a4-b391-6d591a58fdc2", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      //redirectUri:"https://rheotest.azurewebsites.net/.auth/response" 
      redirectUri:"https://rheoview.com/.auth/response"
      //"https://rheoview.azurewebsites.net/.auth/response"
      //"http://localhost:3000/login",// "https://rheotest.azurewebsites.net/.auth/response"
      //redirectUri:"http://localhost:3000/login"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  //scopes for protectedApi
  export const protectedResources= {
    whoami: {
        //endpoint: "https://rheotest.azurewebsites.net/whoami",
        endpoint:"http://localhost:8000/whoami",// "https://rheotest.azurewebsites.net/whoami"
        scopes:
           ["api://55953358-6bcc-4990-9cef-7cd461bd41ae/ReadData"]
        },
        }
    
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read", ...protectedResources.whoami.scopes]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

 
