import { Category, Chart, Boundaries, Riverbank, DisplayConfig } from "./ConfigProvider";
import createDisplayName  from "./createDisplayName";

interface RawConfig{
    [chartName:string]:string[]
}

function buildCategories(rawCategories:string[]):Map<string,Category>{
    const categories:Map<string,Category> = new Map()
    rawCategories.forEach((datakey)=>{
        const displayName = createDisplayName(datakey)
        const category:Category = {
            name:displayName,
            datakey:datakey,
            enabled:true,
            riverbank:{
                riverbankEnabled:false,
                doubleBankEnabled:false,
                targetEnabled:false,
                current:null,
                target:null,
                innerMin:null,
                outerMin:null,
                innerMax:null,
                outerMax:null
            }
        }
        categories.set(displayName,category)
    })
    return categories
}
function buildCharts(raw_config:RawConfig):Map<string,Chart>{
    const charts:Map<string,Chart> = new Map()
    for (const name in raw_config){
        const chart:Chart = {
            name: name,
            enabled:true,
            categories:buildCategories(raw_config[name])
        }
        charts.set(name,chart)
    }
return charts
}
function buildRheoMax():Map<string,Chart>{
    const rawRheomax = [{name:'pH',datakey:'ph'},{name:'Water Oil Ratio',datakey:'wor'},{name:'Displacement',datakey:'Displacement'},{name:'Conductivity WBM',datakey:'CWBM'},{name:'Conductivity OBM',datakey:'COBM'}]
    const charts:Map<string,Chart> = new Map()
    const categories:Map<string,Category> = new Map() 
    rawRheomax.forEach((tag)=>{
        const category:Category = {
            name:tag.name,
            datakey:tag.datakey,
            enabled:true,
            riverbank:{
                riverbankEnabled:false,
                doubleBankEnabled:false,
                targetEnabled:false,
                current:null,
                target:null,
                innerMin:null,
                outerMin:null,
                innerMax:null,
                outerMax:null
            }

        }
        categories.set(tag.name,category)
    })
    const chart:Chart ={
        name:'RheoMax',
        enabled:true,
        categories:categories
    }
    charts.set('RheoMax',chart)
    return charts
}
export function configBuilder(raw_config:string|RawConfig[]):DisplayConfig{
    const viewConfig:DisplayConfig ={
        name:raw_config[0] as string,
        charts: buildCharts(raw_config[1] as RawConfig),
        rheoMax: buildRheoMax(),
        boundaries: 'disabled',
        switch:0

    } 
    return viewConfig   
}


const raw_config = [
    "Standard api",
    {
      "Temp C & Density": [
        "t_inlet_m",
        "rho_vs_e",
        "rho_e_m"
      ],
      "Standard LSR api": [
        "a_api_3rpm_e",
        "a_api_6rpm_e",
        "a_api_100rpm_e"
      ],
      "Standard HSR api": [
        "a_api_200rpm_e",
        "a_api_300rpm_e",
        "a_api_600rpm_e"
      ],
      "Viscosity api": [
        "my_api_pv_e",
        "my_api_yp_e"
      ]
    }
  ] 