import React from "react";
import Stack from '@mui/material/Stack';
import AccountProfile from "./AccountProfile";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const AadSignout = () => {


    return (
        <Stack direction="row" spacing={3} mr={2} sx={{mx: 3}}>
            <AccountProfile />
        </Stack>
    )
};