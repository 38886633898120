import React, { useState } from 'react';
import { globalStateContext, dispatchStateContext } from '../App.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const { palette } = createTheme();
const theme = createTheme({
    palette: {
      customColor: palette.augmentColor({color: {main : '#83EEFF'}})
    },
});

const useGlobalState = () => [
    React.useContext(globalStateContext),
    React.useContext(dispatchStateContext)
  ];

const DynamicForm = (props) => {
    const { max, advice } = props;
    const [state, dispatch] = useGlobalState();
    const [singleBoundary, setSingleBoundary] = useState(false);
    const [doubleBoundary, setDoubleBoundary] = useState(false);


    const handleChange = (event) => {
        let data = event.target.value;
        console.log(data)
        toggleline(data);
    };
    const toggleline = (value) => {
        let temp = state;
        for (let group of temp.displayConfig.charts){
            console.log("reached")
            for (let category of group.categories){
                if(category.datakey===value){
                    category.isEnabled = !category.isEnabled;
                    console.log(category.datakey, category.isEnabled);

                }
            }
        }
        for (let group of temp.displayConfig.rheoMax){
            console.log("reached")
            for (let category of group.categories){
                if(category.datakey===value){
                    category.isEnabled = !category.isEnabled;
                    console.log(category.datakey, category.isEnabled);
                    
                }
            }
        }
        dispatch(temp);
    };
    let rheomaxlist = [];
    let categoryList = [];
    for (let item of state.displayConfig.charts) {
        if (item.group === "RheoMax") {
            rheomaxlist.push(...item.categories);
        } else {
        categoryList.push(...item.categories);
    }}
    for(let item of state.displayConfig.rheoMax){
        if (item.group === "RheoMax") {
            rheomaxlist.push(...item.categories);
        } else {
        categoryList.push(...item.categories);
    }}
    const rheoView = categoryList.map(category => {
        console.log('i am changing!');
        return (
            <ThemeProvider theme={theme}>      
            <FormControlLabel
                value={category.datakey}
                control={ <Switch color='customColor' checked={category.checked} onChange={handleChange} size='small'/>}
                label={<Typography component="p" variant="text1" sx={{color: 'white'}}>{category.name}</Typography>}
                labelPlacement="start"
                checked={category.isEnabled}
                sx={{py: 0.5}}
            />
            </ThemeProvider>
        )
    });
    const singleBoundaryChange = () => {
        if (doubleBoundary === false) {
            let temp = state;
            let singleBoundaryBool = !singleBoundary; 
            temp.config.singleBoundary = singleBoundaryBool;
            setSingleBoundary(singleBoundaryBool);
            console.log(state.config);
            dispatch(state);
        }
    };
    const doubleBoundaryChange = () => {
        if (doubleBoundary === false) {
            let temp = state;
            temp.config.singleBoundary = true;
            temp.config.doubleBoundary = true;
            setSingleBoundary(true);
            setDoubleBoundary(true);
            console.log(state.config);
            dispatch(temp);
        } else {
            let temp = state;
            temp.config.doubleBoundary = false;
            setDoubleBoundary(false);
            console.log(state.config);
            dispatch(temp);
        }
    }
    const boundaries = (
        <>
            <ThemeProvider theme={theme}>      
            <FormControlLabel
                value={"singleBoundary"}
                control={ <Switch color='customColor' checked={singleBoundary} onChange={singleBoundaryChange} size='small'/>}
                label={<Typography component="p" variant="text1" sx={{color: 'white'}}>Single Boundary</Typography>}
                labelPlacement="start"
                checked={singleBoundary}
                sx={{py: 0.5}}
            />
            </ThemeProvider>
            <ThemeProvider theme={theme}>      
            <FormControlLabel
                value={"doubleBoundary"}
                control={ <Switch color='customColor' checked={doubleBoundary} onChange={doubleBoundaryChange} size='small'/>}
                label={<Typography component="p" variant="text1" sx={{color: 'white'}}>Double Boundary</Typography>}
                labelPlacement="start"
                checked={doubleBoundary}
                sx={{py: 0.5}}
            />
            </ThemeProvider>
        </>
    )
    const rheoMax = rheomaxlist.map(category => {
        return (
            <ThemeProvider theme={theme}>     
            <FormControlLabel
                value={category.datakey}
                control={<Switch color='customColor' checked={category.checked} onChange={handleChange} size="small" />}
                label={<Typography component="p" variant="text1" sx={{color: 'lightgray'}}>{category.name}</Typography>}
                labelPlacement="start"
                disabled={category.datakey === "Displacement" ? false : true}
                checked={category.isEnabled}
                sx={{py: 0.5}}
            />
            </ThemeProvider>
        )
    });
    if (advice) {
        return (
            <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
                <FormGroup>
                    {boundaries}
                </FormGroup>
            </FormControl>
        )
    }
    if (!max) {
        return(
            <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
            <FormGroup>
                {rheoView}
            </FormGroup>
            </FormControl>
        )
    } else {
    return (
        <FormControl component="fieldset" variant='standard' sx={{md: 3}}>
            <FormGroup>
                <Typography sx={{color: 'lightgray'}}>Upgrade to enable features</Typography>
                {rheoMax}
            </FormGroup>
        </FormControl>
    )
    }
};


export default DynamicForm;