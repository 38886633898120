import React,{useState} from 'react'
import { FormControl, FormHelperText, Box, Select,} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { createTzRange } from '../../Helper/timeHelpers';

interface DateProps{
    title:string,
    startHandler:SelectHandler,
    endHandler:SelectHandler,
    
    startValue:number,
    endValue:number,
    
}
export interface SelectHandler{
    (event:React.ChangeEvent<HTMLInputElement>): void
}

const optionText = (option:number|string)=>{
    return(option.toString())
  }
function valuetext(value:number) {
    return new Date(value).toISOString().slice(0, -1)
}
function DateForm(props:DateProps){
    const {title, startHandler, startValue,endHandler, endValue} = props
    const [tz, setTz] = useState(new Date().getTimezoneOffset()*60*1000)
    const startString = new Date(startValue-tz).toISOString().slice(0, -8)
    const endString = new Date(endValue-tz).toISOString().slice(0, -8)
    console.log(tz)
    const SelectLabelId = `${title}-select-datetime-label`
    const SelectId = `${title}-datetime-small`
    const tzOptions= createTzRange()
    console.log(tzOptions)
    console.log("start time:", startString)
    console.log("end time:",endString)
    return(
        <Box>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <FormControl sx={{ m: 1, minWidth: 140, px:5 }}>
                    <FormHelperText sx={{color: 'white'}}>{title}</FormHelperText>
                    <input name="Start-Picker" aria-label="Date and time" type="datetime-local" onChange={startHandler} value={startString}/>
                    <input name="End-Picker" aria-label="Date and time" type="datetime-local" onChange={endHandler} value={endString}/>
                </FormControl>        
            </Grid>
        </Grid>
        </Box>
       
    )
}

export default DateForm