
import React, { ReactNode,createContext, useReducer, type Dispatch, useContext } from 'react';

interface Props {
    children?: ReactNode
    // any props that come into the component
  }
  //export Interfaces
interface RenderAction{
    type:'flipSwitch'
}
const RenderContext = createContext<0|1|null>(null)
const RenderDispatchContext = createContext<Dispatch<RenderAction>|null>(null)

export function useRender(){
    return useContext(RenderContext)
}

export function  useRenderDispatch(){
    return useContext(RenderDispatchContext)
}

export default function RenderProvider({children}:Props){
    const [render,dispatch] = useReducer(RenderReducer,0)

    return(
        <RenderContext.Provider value={render}>
            <RenderDispatchContext.Provider value={dispatch}>
                {children}
            </RenderDispatchContext.Provider>
        </RenderContext.Provider>
    )
}
function RenderReducer(render:1|0, action:RenderAction){
    switch(action.type){
        case'flipSwitch':{
            return flipSwitch(render)
        }
    }
}

function flipSwitch(render:1|0){
    if(render==1){
        return 0
    }
    return 1
}