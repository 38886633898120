import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMsal } from "@azure/msal-react";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';


function handleLogout(instance, popupstate) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
    popupstate.close();
}
function handleProfile(navigate, popupstate) {
    popupstate.close();
    navigate('/Profile');
}
const useStyles = makeStyles((theme) => ({
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "#0A1929"
      }
    }
  }));

const AccountProfile = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const classes = useStyles();
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
            <React.Fragment>
            <Tooltip title="my account">
            <IconButton variant="contained" {...bindTrigger(popupState)}>
                <AccountCircleIcon fontSize="large" sx={{color: 'white'}}/>
            </IconButton>
            </Tooltip>
            <Menu 
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={classes.menu}
            >
                <MenuItem onClick={() => handleProfile(navigate ,popupState)} sx={{color: 'white'}}>
                    <ListItemIcon>
                        <Settings fontSize="small" sx={{color: 'white'}}/>
                    </ListItemIcon>
                    Account Settings
                </MenuItem>
                <MenuItem onClick={() => handleLogout(instance, popupState)} sx={{color: 'white'}}>
                    <ListItemIcon>
                        <Logout fontSize="small" sx={{color: 'white'}} />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            </React.Fragment>
        )}
        </PopupState>
    );
};
export default AccountProfile;