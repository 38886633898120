import React from 'react';
import Google from '../components/GoogleMaps';
import { Grid } from '@mui/material';
const MapsPage = () => {
    return (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item md={6}>
                <Google />
            </Grid>
        </Grid>
                 
    );
}
export default MapsPage;