export function TooltipLabelFormat(value){
    
    let date = new Date(value);
    let minutes = date.getMinutes().toString()
    if (minutes.length==1){
        minutes=0+minutes;
    }
    let hours = date.getHours().toString()
    if (hours.length==1){
        hours=0+hours;
    }
    let seconds = date.getSeconds().toString()
    if (seconds.length==1){
        seconds=0+seconds;
    }
    let dateString = hours + ':'+ minutes + ':' + seconds;
return dateString
}
export default TooltipLabelFormat;