export function HistoricTickFormat(value){
    console.log(value)
    const options = { weekday: 'short'};
    let date = new Date(value);
    let day = new Intl.DateTimeFormat('en-US', options).format(date);
    let hours = date.getHours().toString()
    if (hours.length==1){
        hours=0+hours;
    }const tick = day+' '+hours
    return tick
};
export default HistoricTickFormat;