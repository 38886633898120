import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState, useRef} from "react";
import { Paper, Typography, CircularProgress, Box, MenuItem, FormControl, Select, FormHelperText, Button} from '@mui/material';
import { globalStateContext, dispatchStateContext } from '../App.js';
import { loginRequest, protectedResources } from "../authConfig";
import { BootstrapInput, MyIcon, useStyles } from './TargetForm.js';
import { useNavigate, useLocation } from 'react-router-dom';
import BuildViewConfig from "../Helper/BuildViewConfig.js";
import {useConfig, useConfigDispatch} from './ViewConfig/ConfigProvider'
import { configBuilder } from "./ViewConfig/ConfigBuilder";
const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext)
];

function SkidSelector() {
    const [state, dispatch] = useGlobalState();
    const [selectedSkid, setSelectedSkid] = useState(null);
    const { instance, accounts, inProgress } = useMsal();
    const [skidOptions, setSkidOptions] = useState(null);
    const [skidsFetched, setSkidsFetched] = useState(null);
    const [availableSkids, setAvailableSkids] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const classes = useStyles();
    const locationState = useLocation().state;
    const viewConfig = useConfig();
    const viewConfigDispatch = useConfigDispatch();

    const handleSkidClick = (event) => {
        console.log("setting new selected skid")
        const skid = event.target.value
        setSelectedSkid(skid);
        //navigate('/Live', {state:{targetSkid:skid}})

    };
    const handleRedirect = () => {
      console.log("handling redirect");
      fetchDefaultView();
      console.log(state.displayConfig);
      navigate(locationState.origin, {state:{targetSkid:selectedSkid}});

  };
    
    const fetchAvailableSkids = async () => {
        const request = {
          scopes: protectedResources.whoami.scopes,
          account: accounts[0],
        };
        instance.acquireTokenSilent(request).then((response) => {
          const headers = new Headers();
          const bearer = response.accessToken;
          console.log(bearer)
          const end = new Date().toISOString();
          headers.append("Authorization", bearer);
          const options = {
            method: "GET",
            headers: headers
          };
    
          const url = new URL("https://rheoport-api.azurewebsites.net/GetAvailableSkids");
          fetch(url, options)
          .then(res => res.json())
          .then((items) =>{
            console.log("available skids: ", items.skids);
            setSkidsFetched(items.skids)
      })
      .catch(error => {
        console.log(error);
        setError('No skids available skids found for this user.');
      });
      })
    };

    
    
    const fetchDefaultView = async() => {
      const request = {
        scopes: protectedResources.whoami.scopes,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        const headers = new Headers();
        const bearer = response.accessToken;
        const end = new Date().toISOString();
        headers.append("Authorization", bearer);
        const options = {
          method: "GET",
          headers: headers
        };
  
        const url = new URL("https://rheoport-api.azurewebsites.net/GetDefaultSkidView");
        url.search = new URLSearchParams({
            Skid: selectedSkid
          });
        fetch(url, options)
        .then(res => res.json())
        .then((items) =>{
          console.log(items);
          const rheoMax = state.data[state.data.length -1]
          const displayConfig = BuildViewConfig(items,rheoMax)
        //state.displayConfig = displayConfig 
        const config = configBuilder(items)
          //state.displayConfig = displayConfig
          console.log('dispatching:',config) 
          viewConfigDispatch({
            type:'updateConfig',
            chart: null,
            category: null,
            config: config,
          })
    })
    .catch(error => {
      console.log(error);
    });
    });
    };
    
    useEffect(()=>{
      fetchAvailableSkids()
    }, [])

    useEffect(()=>{
        if (skidOptions){   
            console.log("setting to true")
            setAvailableSkids(true)
            setSelectedSkid(skidsFetched[0])
            console.log(skidOptions)
        }
    },[skidOptions])

    useEffect(()=>{
      if (skidsFetched){ 
        if(skidsFetched.length==1){
          const skid = skidsFetched[0]
          setSelectedSkid(skid);
          navigate('/Live', {state:{targetSkid:skid}})
        }  
        const options = skidsFetched.map(option => {
          console.log(option)
          return (
            <MenuItem key={option.toString()} value={option.toString()}>{option} </MenuItem>
          )
        });
        setSkidOptions(options);
          //console.log(skidsFetched)
      }
  },[skidsFetched])
    
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: 'center'}}>
        {availableSkids 
            ?<div>
              <FormControl sx={{minWidth: 140, px:4}}>
            <FormHelperText sx={{color: 'white'}}>Select Skid</FormHelperText>
            <Select
              sx={{backgroundColor: '#273746'}}
              MenuProps={{
                classes: {
                  paper: classes.select,
                }
              }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedSkid}
              onChange={handleSkidClick}
              input={<BootstrapInput />}
              inputProps={{classes: {input: classes.input, icon: classes.icon}}}
              IconComponent={MyIcon}
              
            >
            {skidOptions}
            </Select>
          </FormControl>
          <Button variant="contained" size="large" sx={{mt:3.5}} onClick ={handleRedirect}>Select</Button>
            </div>
            :<CircularProgress />
        }
        
        
      </Box>
    );
  }

  export default SkidSelector;