export function updateStats (newStats, oldStats) {
  //console.log(newStats, oldStats)
    if(oldStats !== null){
        let tempChanges = {};
          Object.entries(oldStats).forEach(([key, val]) => {
            if(key !== 'items' && key !== 'timestamp'){
              let Min = false;
              let Max = false;
              let Average = false;
              if(!newStats[key]){
                tempChanges[key] = {min: Min, max: Max, average: Average};
                return tempChanges
              }try{
                if (parseFloat((newStats[key].Min).toFixed(2)) !== parseFloat((val.Min).toFixed(2))) {
                  Min = true;
                }
                if (parseFloat((newStats[key].Max).toFixed(2)) !== parseFloat((val.Max).toFixed(2))) {
                  Max = true;
                }
                if (parseFloat((newStats[key].Sum / newStats[key].Count).toFixed(2)) !== parseFloat((val.Sum / val.Count).toFixed(2))) {
                  Average = true;
                }
                tempChanges[key] = {min: Min, max: Max, average: Average};
              }catch(exception){
                tempChanges[key] = {min: true, max: true, average: true}
                console.log(exception)
                console.log('old:',oldStats)
                console.log('new:',newStats)
                console.log('category:',newStats[key])
              }
                
            }
          });
    //      console.log(tempChanges)
          return tempChanges
        } else {
          let tempChanges = {};
          Object.entries(newStats).forEach(([key, val]) => {
            if(key !== 'items' && key !== 'timestamp'){
              tempChanges[key] = {min: false, max: false, average: false};
            }
          })
          return tempChanges
        };
};
export default updateStats;