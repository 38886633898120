import { DataPoint } from "../Pages/Historic"
import { StatsCollection } from "./Chart/chartIsActive"

export
class StatsObject{

    constructor(items){
        this.items = items
        this.calculateStats()
    }
    toCollection(){
        //console.log(this)
       let result = {
        items:this.items
       }
       let keys = Object.keys(this)
        for (let key of keys){
            //console.log(`checking ${key}`)
        if(key != 'items'){
            //console.log(key,this[key])
            result[key]=this[key]
        }
       }
       //console.log(result)
       return result
    }
    calculateStats(){
        
        this.parseItems()
        
    }
    parseItems(){
        //loops over items
        for(let item of this.items){
            this.parseItem(item)
        }
    }
    parseItem(item){
        //Looping trough keys in item
        for(let key of Object.keys(item)){
            if(!this.checkIfKeyPresent(key)){
                if(item[key]==null){
                    continue
                }
                this.addCategory(key)
            }
            if(item[key]==null){
                continue
            }
            this.updateSum(key,item[key])
            this.updateCurrent(key,item[key])
            this.updateCount(key)
            this.updateMinMax(key,item[key])
        }
    }
    checkIfKeyPresent(newKey){
        let keys = Object.keys(this)
        for (let key of keys){
            //console.log(`checking ${key}`)
            if(key === newKey){
                return true
            }
        }
        return false    
    }
    addCategory(key){
        this[key]={
            Sum: 0,
            Count:0,
            Min:999999,
            Max: 0,
            Current:0
        }
    }
    updateSum(key,value){
       //console.log(`updaring ${key} with value: ${value}`)
        this[key].Sum +=value;
        //console.log(this[key])
    }
    updateCurrent(key,value){
        //console.log(`updaring ${key} with value: ${value}`)
          this[key].Current = value;
          //console.log(this[key])
      }
    updateCount(key){
        this[key].Count ++;
    }
    updateMinMax(key,value){
        if(value< this[key].Min){
            this[key].Min = value
        }
        if(value> this[key].Max){
            this[key].Max = value
        }
    }
}
export default StatsObject;