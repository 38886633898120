export function epochSecondsToHours(seconds:number):number{
    console.log('delta seconds:', seconds)
    const remainder = seconds%3600
    seconds = seconds+remainder
    return seconds/3600000
}

export function createTzRange(){
    const options = []
    let tz = -12
    while (tz<14){
        options.push(tz)
        tz=tz+1
    }
    return options
}

export function roundEpochUpToHour(seconds:number):number{
    const remainder = seconds%3600
    seconds = seconds+remainder
    return seconds
}