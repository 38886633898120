
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, CartesianAxis, ResponsiveContainer, Label } from 'recharts';
import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import AxisTicks from '../Helper/AxisTicks';
import TickFormat from '../Helper/TickFormat';
import TooltipLabelFormat from '../Helper/TooltipLabelFormat';
import TooltipFormat from '../Helper/TooltipFormat';


const width = parseInt(400*0.8);
const data = [
    {
      timestamp: '2020-01-01',
      data: 80,
    },
    {
      timestamp: '2020-01-02',
        data: 120,
      },
      {
        timestamp: '2020-01-03',
        data: 110,
      },
      {
        timestamp: '2020-01-04',
        data: 140,
      },
      {
        timestamp: '2020-01-05',
        data: 260,
      },
      {
        timestamp: '2020-01-06',
        data: 180,
      },
      {
        timestamp: '2020-01-07',
        data: 280,
      },
  ];
const DisplacementChart = (props) => {
    const { items } = props;

    if (items.filter(e => e.dp_hsb_f)) {
    return(
    <Box name='GroupBox' sx={{display:'flex', flexDirection:'column', justifyItems:'center', height: {xs: '250px', md: '500px'}, width: {xs: '400px', md: '100%'}}}>
        <Typography variant="h6" component='caption' sx={{color: 'white', mx:'auto'}}>Displacement</Typography>
    <ResponsiveContainer height="100%" width="100%" className="demo-container">
     <AreaChart className='charts'  data={items} margin={{ top: 10, right: 30, bottom: 5, left: 10 }}>
      <XAxis dataKey="timestamp" type="number" tickCount={6} tickFormatter={TickFormat} domain={[Date.now()-60000, Date.now()]} tickLine={false}  stroke="white"/>
      <YAxis stroke="white" datakey="dp_hsb_f" dy={-5}  tickLine={false} tick={{display: "hidden"}} />
      <Legend verticalAlign="bottom" align='center' height={40} margin={{left:50,right:10}}/>
      <Area type="monotone" dataKey="dp_hsb_f" name="Displacement" stroke="#83EEFF" fill="#83EEFF" axisLine={false} isAnimationActive={false} connectNulls={true}/>
      <Tooltip contentStyle={{backgroundColor:'#273746', color:'white'}} labelFormatter={TooltipLabelFormat} formatter={TooltipFormat}/>
     </AreaChart>
     </ResponsiveContainer>
    
     </Box>
      
    )
    } else {
      return(
        <Box name='GroupBox' sx={{display:'flex', flexDirection:'column', justifyItems:'center', height: {xs: '250px', md: '500px'}, width: {xs: '400px', md: '100%'}}}>
            <Typography variant="h6" component='caption' sx={{color: 'white', mx:'auto'}}>Displacement</Typography>
        <ResponsiveContainer height="100%" width="100%" className="demo-container">
         <AreaChart className='charts'  data={data} margin={{ top: 10, right: 30, bottom: 5, left: 10 }}>
          <CartesianAxis />
          <CartesianGrid stroke="#6f97b5" fill='#34495e'  />
          <XAxis dataKey="timestamp"/>
          <YAxis stroke="white" datakey="data" />
          <Tooltip  />
          <Legend verticalAlign="bottom" align='center' height={40} margin={{left:50,right:10}}/>
          <Area type="monotone" dataKey="data" stroke="#83EEFF" fill="#83EEFF" />
         </AreaChart>
         </ResponsiveContainer>
        
         </Box>
      )
    }
    }
  
  export default DisplacementChart;