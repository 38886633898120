export function TickFormat(value){
    
    let date = new Date(value);
      //  console.log('formatting value into hrf')
        let minutes = date.getMinutes().toString()
        if (minutes.length==1){
            minutes=0+minutes;
        }
        let hours = date.getHours().toString()
        if (hours.length==1){
            hours=0+hours;
        }
        let dateString = hours + ':'+ minutes;
        //console.log(`format Finished dateString = ${dateString}`)
    return dateString
    
   
}
export default TickFormat;