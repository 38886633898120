import React, { useEffect} from "react";
import Typography from '@mui/material/Typography';
import Google from '../components/GoogleMaps';
import TablePage from './TablePage';
import { authenticatedFetch } from '../Helper/Fetch/fetch';
import {useSkidConfig, useSkidConfigDispatch} from '../components/AvailableSkids/SkidsProvider'
import { useMsal } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";



const styles = {
    root: {
      display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: '24px',
        height: '100vh',
        overflow: 'auto',
    },
    mapContainer: {
        marginLeft: -22,
    },
    appBarSpacer: {
        minHeight: '30px',
    },
};
const fetchSkids = async(accounts, instance, dispatch) =>{
const configURL = 'https://rheoport-api.azurewebsites.net'
  var fetchURL = configURL+"/GetAvailableSkids"
        console.log(`Hello ${accounts[0].name} your email is: ${accounts[0].username}`)
        var params = {}
        const request = {
          scopes: protectedResources.whoami.scopes,
          account: accounts[0]
        }
    
        var req = authenticatedFetch(request,instance,params,fetchURL)
        console.log('instance:',instance)
        req.then(res => res.json())
        .then((items) =>{
          console.log("available skids: ", items.skids);
          dispatch({
            type:'addSkids',
            skids:items.skids
          })
          
        })  
}

function  HomePage()  {
    const { instance, accounts, inProgress } = useMsal();
    const SkidDispatch = useSkidConfigDispatch()
    useEffect(()=>{
        if(accounts[0]){
          fetchSkids(accounts, instance, SkidDispatch)
        }  
      },[accounts])
    return (
        <div style={{width:'100%'}}>
        <div style={styles.appBarSpacer} />
            <Typography variant="h4" gutterBottom component="h2" color="white">
            </Typography>
            <Typography component="div" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Google key={'google'}/>
            </Typography>
            <Typography variant="h4" style={styles.appBarSpacer} color="white" />
            <Typography component="div" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <TablePage />
            </Typography>
        </div>
    );
};
export default HomePage;